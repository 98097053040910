import * as R from 'ramda';
import * as React from 'react';

import { getOfferTypeList } from '../../../../../Fields/shared/components/OfferTypeSelect';
import { getOfferTypeLabel } from '../../../../../Fields/shared/utils/offerType';
import { FOfferType } from '../../../../../JsonQuery';
import { QuickFilterButton } from '../../../components/QuickFilters/QuickFilterButton';
import { OfferTypeSelectModal } from '../../../components/filters/OfferTypeSelectModal';
import { EQuickFilterType } from '../../../types/quickFilter';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';
import { useOfferType } from '../../../utils/useOfferType';

export const RootOfferTypeContainer: React.FC = () => {
  const { onChange } = useContext();
  const dealType = useDealType();
  const offerType = useOfferType();

  const [isOpen, setIsOpen] = React.useState(false);
  const options = React.useMemo(
    () => getOfferTypeList({ dealType, offerType, isSearchFlatShareEnabled: false }),
    [dealType, offerType],
  );
  const selectedOptions = React.useMemo(
    () =>
      R.flatten(options.map(t => t.options))
        .filter(type => (offerType & type.value) !== 0)
        .map(offerType => offerType.value),
    [options, offerType],
  );
  const label = React.useMemo(() => getOfferTypeLabel(dealType, offerType), [dealType, offerType]);

  const handleSelect = React.useCallback(
    (nextOfferTypes: FOfferType[]) => {
      const nextOfferType = nextOfferTypes.reduce(
        (previousValue, currentValue) => previousValue | currentValue,
        FOfferType.Unexpected,
      );

      if (nextOfferType !== offerType && (nextOfferType !== FOfferType.Flat || (offerType & FOfferType.Flat) === 0)) {
        onChange({ action: 'setOfferType', arguments: [nextOfferType] });
      }

      setIsOpen(false);
    },
    [onChange, offerType],
  );

  const handleClose = React.useCallback(() => setIsOpen(false), []);

  return (
    <>
      <QuickFilterButton text={label || ''} onClick={() => setIsOpen(true)} type={EQuickFilterType.Advanced} />
      <OfferTypeSelectModal
        open={isOpen}
        options={options}
        selected={selectedOptions}
        onSelect={handleSelect}
        onClose={handleClose}
      />
    </>
  );
};
