import { normalizeDealType } from './helpers';
import { dealTypeFromJsonQuery, getTermsValue, JsonQuery, term, terms, TJsonQuery } from '../../../packages/JsonQuery';
import { lruCache } from '../../../packages/LRUCache';
import { EOfficeType } from '../../../packages/api-models/common/json_query';
import { IApplicationContext } from '../../types/applicationContext';
import { ISearchMeta } from '../../types/searchMeta';
import { fetchSearchMeta } from '../fetchSearchMeta';

interface IFetchCommercialMetaParameters {
  jsonQuery: TJsonQuery;
}

const STUB_COMMERCIAL_JSON_QUERY: TJsonQuery = {
  _type: 'commercialrent',
  engine_version: term(2),
  office_type: terms([
    EOfficeType.Office,
    EOfficeType.TradeArea,
    EOfficeType.Warehouse,
    EOfficeType.PublicCatering,
    EOfficeType.FreeAppointmentObject,
    EOfficeType.Manufacture,
    EOfficeType.AutoService,
    EOfficeType.Building,
    EOfficeType.DomesticServices,
  ]),
};

async function fetchCommercialMeta(
  context: IApplicationContext,
  { jsonQuery }: IFetchCommercialMetaParameters,
): Promise<ISearchMeta> {
  const dealType = normalizeDealType(dealTypeFromJsonQuery(jsonQuery));
  const regionId = getTermsValue('region')(jsonQuery);

  const commercialJQ = new JsonQuery(STUB_COMMERCIAL_JSON_QUERY);

  commercialJQ.setDealType(dealType);
  commercialJQ.setRegion(regionId);

  const searchMeta = await fetchSearchMeta(context, {
    jsonQuery: commercialJQ.toJSON(),
    cancellationTokenKey: 'fetchCommercialMeta',
  });

  return searchMeta;
}

const fetchCommercialMetaWithCache = lruCache(fetchCommercialMeta, {
  key: 'commercialMeta',
  options: { ttl: 30 * 60 * 1000 },
});

const fetchCommercialMetaCached = (context: IApplicationContext, parameters: IFetchCommercialMetaParameters) => {
  const { jsonQuery } = parameters;

  const dealType = normalizeDealType(dealTypeFromJsonQuery(jsonQuery));
  const regionId = getTermsValue('region')(jsonQuery);

  return fetchCommercialMetaWithCache(`commercialMeta.${regionId}.${dealType}`, context, context, parameters);
};

export async function fetchCommercialMetaCachedSafe(
  context: IApplicationContext,
  parameters: IFetchCommercialMetaParameters,
): Promise<ISearchMeta | null> {
  try {
    return await fetchCommercialMetaCached(context, parameters);
  } catch (ex) {
    const { logger } = context;

    logger.warning('[Degradation] commercialMeta', ex);

    return null;
  }
}
