import { UIText2 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './FilterRow.css';

interface IFilterRowProps {
  id?: string;
  label?: React.ReactNode;
}

export const FilterRow: React.FC<React.PropsWithChildren<IFilterRowProps>> = props => {
  const { children } = props;

  return (
    <div role="group" aria-labelledby={props.id} className={styles['container']}>
      {props.label && (
        <div className={styles['title']}>
          <UIText2 as="div" id={props.id}>
            {props.label}
          </UIText2>
        </div>
      )}
      <div className={styles['content']}>{children}</div>
    </div>
  );
};
