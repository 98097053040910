import { CancellationToken } from '@cian/http-api';

import { TJsonQuery } from '../../../packages/JsonQuery';
import { ResponseError } from '../../errors';
import { prepareSearchMeta } from '../../mappers/prepareSearchMeta';
import { fetchGetMeta, TGetMetaResponse } from '../../repositories/search-offers-index/v1/get-meta';
import { IApplicationContext } from '../../types/applicationContext';
import { ISearchMeta } from '../../types/searchMeta';

interface IFetchSearchMetaParameters {
  jsonQuery: TJsonQuery;
  cancellationTokenKey?: string;
}

export async function fetchSearchMeta(
  context: IApplicationContext,
  { jsonQuery, cancellationTokenKey = 'fetchSearchMeta' }: IFetchSearchMetaParameters,
): Promise<ISearchMeta> {
  const { cancellationTokenManager, httpApi } = context;

  let cancellationToken: CancellationToken | undefined;
  if (cancellationTokenManager) {
    cancellationToken = cancellationTokenManager.getTokenAndCancelPrevious(cancellationTokenKey);
  }

  const searchMetaData: TGetMetaResponse = await fetchGetMeta({
    httpApi,
    parameters: {
      jsonQuery,
    },
    config: { cancellationToken },
  });

  if (searchMetaData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchSearchMeta',
      message: searchMetaData.response.message,
      details: {
        error: searchMetaData.response.errors,
      },
    });
  }

  return prepareSearchMeta(searchMetaData.response);
}
