import { lruCache } from '../../../packages/LRUCache';
import { ResponseError } from '../../errors';
import {
  fetchGetSearchTypes,
  IGetSearchTypesResponse,
  TGetSearchTypesResponse,
} from '../../repositories/newbuilding-promos/v1/get-search-types';
import { IApplicationContext } from '../../types/applicationContext';

interface IFetchPromoSearchTypesParameters {
  regionId?: number;
  subdomain?: string;
}

export const fetchPromoSearchTypes = async (
  context: IApplicationContext,
  parameters: IFetchPromoSearchTypesParameters,
): Promise<IGetSearchTypesResponse | null> => {
  const { httpApi, logger } = context;

  try {
    const promoSearchTypes: TGetSearchTypesResponse = await fetchGetSearchTypes({
      httpApi,
      parameters,
    });

    if (promoSearchTypes.statusCode !== 200) {
      throw new ResponseError({
        domain: 'fetchGetSearchTypes',
        message: promoSearchTypes.response.message,
        details: {
          error: promoSearchTypes.response.errors,
        },
      });
    }

    return promoSearchTypes.response;
  } catch (error) {
    logger.error(error, {
      degradation: 'Failed to get promo search types',
    });

    return null;
  }
};

const fetchPromoSearchTypesWithCache = lruCache(fetchPromoSearchTypes, { key: 'promoSearchTypes' });

export const fetchPromoSearchTypesCached = async (
  context: IApplicationContext,
  parameters: IFetchPromoSearchTypesParameters,
) => {
  return fetchPromoSearchTypesWithCache(`promoSearchTypes.${parameters.regionId}`, context, context, parameters);
};
