import { initGlobalEventsLogs } from '@cian/events-log';

import { setupDependencies, initDependencies } from './dependencies';
import { start } from './start';

setupDependencies();
initDependencies();
initGlobalEventsLogs();

start();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((module as any).hot) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (module as any).hot.accept();
}
