import { WebComponent } from '@cian/mf-registry/browser';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import { Application } from '../../shared/containers/QuickFiltersMicrofrontendApp';
import { IApplicationContext } from '../../shared/types/applicationContext';
import { IReduxStore } from '../../shared/types/redux';

export function createCustomElementConstructor(
  context: IApplicationContext,
  store: IReduxStore,
): CustomElementConstructor {
  class QuickFilters extends WebComponent {
    private applicationContext: IApplicationContext;
    private reactRoot: ReactDOM.Root;

    protected microfrontendName = context.config.getStrict<string>('projectName');
    protected imageVersion = '';
    protected runtimeName = '/public/v1/get-quick-filters-microfrontend/';

    public async connectedCallback(): Promise<void> {
      const instanceId = this.getAttribute('instance') || undefined;
      this.applicationContext = { ...context, config: context.config.attach({ instanceId }) };
      this.imageVersion = this.applicationContext.config.getStrict<string>('version.commit');
      super.connectedCallback();
    }

    public async disconnectedCallback() {
      this.reactRoot.unmount();
      super.disconnectedCallback();
    }

    public async render() {
      const reactElement = <Application {...this.props} context={this.applicationContext} reduxStore={store} />;

      if (this.shouldHydrate) {
        this.reactRoot = ReactDOM.hydrateRoot(this, reactElement);
      } else {
        this.reactRoot = ReactDOM.createRoot(this);
        this.reactRoot.render(reactElement);
      }
    }
  }

  return QuickFilters;
}
