export enum ESearchMetaStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export type TPlaceholderKey =
  | 'minPrice'
  | 'maxPrice'
  | 'minTotalArea'
  | 'maxTotalArea'
  | 'minLivingArea'
  | 'maxLivingArea'
  | 'minKitchenArea'
  | 'maxKitchenArea';

export interface ISearchMeta {
  count: number;
  queryString: string;
  seoPath: string | null;
  subdomain: string;
  placeholders: Partial<Record<TPlaceholderKey, string>>;
}

export interface ISearchMetaState extends ISearchMeta {
  status: ESearchMetaStatus;
}
