import { FOfferType } from '../../../../../../JsonQuery';
import { IOfferTypeSelectOptionsGroup } from '../../types';

export const OFFER_TYPES_RENT_RESIDENTIAL: IOfferTypeSelectOptionsGroup[] = [
  {
    label: 'Квартиры и комнаты',
    options: [
      { label: 'Квартира', value: FOfferType.Flat },
      { label: 'Комната', value: FOfferType.Room },
      { label: 'Койко-место', value: FOfferType.Bed },
    ],
  },
  {
    label: 'Дома и участки',
    options: [
      { label: 'Дом, дача', value: FOfferType.House },
      { label: 'Часть дома', value: FOfferType.HousePart },
      { label: 'Таунхаус', value: FOfferType.Townhouse },
    ],
  },
  { label: 'Гаражи', options: [{ label: 'Гараж', value: FOfferType.Garage }] },
];
