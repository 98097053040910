/* eslint-disable max-lines */
export enum ERentTime {
  FromYear = '0',
  Daily = '1',
  FewMonths = '2',
  NotDaily = '!1',
  AnyTerm = '*',
}

export enum EBuildingStatus {
  Old = 1,
  New = 2,
}

export enum EPublishPeriod {
  Any = 0,
  Today = -2,
  Hour = 3600,
  Day = 86400,
  Week = 604800,
  TenDays = 864000,
  Month = 2592000,
}

export enum EHouseMaterial {
  None = 0,
  Brick = 1,
  Monolith = 2,
  Panel = 3,
  Block = 4,
  Wood = 5,
  Stalin = 6,
  Shield = 7,
  BrickMonolith = 8,
  Wireframe = 10,
  AerocreteBlock = 11,
  GasSilicateBlock = 12,
  FoamConcreteBlock = 13,
}

export enum ERepair {
  Without = 1,
  Cosmetic = 2,
  Euro = 3,
  Design = 4,
}

export enum EMaxPrepay {
  Any = 0,
  Month = 1,
  TwoMonths = 2,
  ThreeMonths = 3,
}

export enum ERoomType {
  Room = 0,
  FlatOneRoom = 1,
  FlatTwoRooms = 2,
  FlatThreeRooms = 3,
  FlatFourRooms = 4,
  FlatFiveRooms = 5,
  FlatManyRooms = 6,
  FlatFreeLayout = 7,
  FlatShared = 8,
  Studio = 9,
  Bed = 10,
}

export enum ERoomsTotal {
  Any = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
}

export enum ETenant {
  Any = 1,
  Family = 2,
  Woman = 3,
  Man = 4,
}

export enum EBedroomsCount {
  Any = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
}

export enum EParkingType {
  Rooftop = 1,
  Underground = 2,
  Ground = 3,
  Multilevel = 4,
}

export enum EHeatingType {
  None = 1,
  Gas = 2,
  Coal = 3,
  Furnace = 4,
  Fireplace = 5,
  Electric = 6,
  AutonomousGas = 6300,
  Diesel = 6301,
  SolidFuelBoiler = 6302,
}

export enum ELandStatusType {
  Gardening = 1,
  IndividualHousingConstruction = 2,
  IndustrialLand = 3,
  Farm = 4,
  CountryhouseNonCommercialPartnership = 5,
  InvestmentProject = 6,
  PrivateFarm = 7,
}

export enum EWCType {
  Any = 0,
  United = 1,
  Separated = 2,
}

export enum ESuburbanOfferFilter {
  Any = 0,
  Builder = 1,
  Individual = 2,
  Homeowner = 3,
}

export enum ESuburbanWCType {
  Any = 0,
  Inside = 1,
  Outside = 2,
}

export enum EWCCount {
  Any = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
}

export enum EWindowsType {
  Any = -1,
  Courtyard = 0,
  Street = 1,
}

export enum EMetroTravelType {
  Any = '0',
  AgentFoot = '1',
  AgentTransport = '-1',
  MapFoot = '2',
  MapTransport = '-2',
}

export enum ELayoutType {
  Any = 0,
  IsolatedRooms = 1,
  UnitedRooms = 2,
}

export enum EGarageType {
  Any = 0,
  CarPlace = 1,
  Garage = 2,
  Box = 3,
}

export enum EGarageKind {
  Builtin = 1,
  Capital = 2,
  Shell = 3,
  Selfbuilt = 4,
}

export enum EGarageMaterial {
  Metal = 1,
  Brick = 2,
}

export enum EGarageStatus {
  Cooperative = 1,
  Ownership = 2,
  Agreement = 3,
}

export enum EPriceType {
  SM = 1,
  Total = 2,
}

export enum ESaleType {
  Any = 0,
  Free = 1,
  Alternative = 2,
  F3 = 3,
  Reassignment = 4,
  GSKContract = 5,
  PreliminaryContract = 6,
  InvestmentContract = 7,
}

export enum EElevators {
  Any = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
}

export enum ECurrencyType {
  Dollar = 1,
  Rouble = 2,
  Euro = 3,
}

export enum EObjectType {
  House = 1,
  Housepart = 2,
  Land = 3,
  Townhouse = 4,
}

export enum EOfficeType {
  Office = 1,
  TradeArea = 2,
  Warehouse = 3,
  PublicCatering = 4,
  FreeAppointmentObject = 5,
  Garage = 6,
  Manufacture = 7,
  AutoService = 9,
  Business = 10,
  Building = 11,
  DomesticServices = 12,
  Coworking = 13,
}

/**
 * @enum
 * @description Тип коворкинга
 */
export enum ECoworkingOfferType {
  /**
   * @field
   * @description Гибкий офис
   */
  FlexibleOffice = 1,
  /**
   * @field
   * @description Закреплённое рабочее место
   */
  FixedWorkSpace = 2,
  /**
   * @field
   * @description Незакреплённое рабочее место
   */
  FreeWorkSpace = 3,
}

export enum ECategoryType {
  CommercialLandRent = 'commercialLandRent',
  CommercialLandSale = 'commercialLandSale',
}

export enum EBuildingClassType {
  A = 1,
  APlus = 2,
  B = 3,
  BPlus = 4,
  C = 5,
  CPlus = 6,
  D = 7,
  BMinus = 8,
}

export enum EBuildingElevatorType {
  Service = 1,
  Telpher = 2,
  Passenger = 3,
}

export enum EBuildingCraneType {
  Overhead = 1,
  Beam = 2,
  Railway = 3,
  Goat = 4,
}

export enum EBuildingGatesType {
  Ramp = 1,
  Dock = 2,
  Ground = 3,
}

export enum EBuildingHeatingType {
  Without = 1,
  Central = 2,
  Autonomous = 3,
}

export enum EPlacementType {
  ShoppingMall = 1,
  StreetRetail = 2,
}

export enum EEntranceType {
  SeparateCourtyard = 1,
  SeparateStreet = 2,
  CommonCourtyard = 3,
  CommonStreet = 4,
}

export enum EConditionType {
  OfficeDecoration = 1,
  FullDecorationNeeded = 2,
  MajorRepairNeeded = 3,
  CosmeticRepairNeeded = 4,
  RegularRepair = 5,
  DesignerRepair = 6,
}

export enum EHouseLineType {
  First = 1,
  Second = 2,
}

export enum EEstateType {
  Owned = 1,
  Rent = 2,
}

export enum EContractType {
  DirectRent = 1,
  SubRent = 2,
  PermTransfer = 3,
  ObjSale = 4,
}

export enum ESortValue {
  Default = 'default',
  Price = 'price_object_order',
  SquarePrice = 'price_square_order',
  Area = 'area_order',
  LandArea = 'site_order',
  WalkingTime = 'walking_time',
  MKAD = 'mcad',
  StreetName = 'street_name',
  UserId = 'id_user',
}

export enum EEOfferCategory {
  AutoService = 'autoService',
  Bedroom = 'bedroom',
  Building = 'building',
  CommercialLand = 'commercialLand',
  DomesticServices = 'domesticServices',
  Flat = 'flat',
  FlatNew = 'flatNew',
  FlatOld = 'flatOld',
  FreeAppointmentOffice = 'freeAppointmentOffice',
  Garage = 'garage',
  House = 'house',
  Housepart = 'housepart',
  Manufacture = 'manufacture',
  Office = 'office',
  Part = 'part',
  PublicCatering = 'publicCatering',
  ReadyMadeBusiness = 'readyMadeBusiness',
  Room = 'room',
  Storehouse = 'storehouse',
  Townhouse = 'townhouse',
  Tradearea = 'tradearea',
}

export enum EFacility {
  Bath = 'bath',
  Conditioner = 'conditioner',
  Dishwasher = 'dish_washer',
  Fridge = 'has_fridge',
  Garage = 'garage',
  Internet = 'internet',
  KidsAllowed = 'kids',
  KitchenFurniture = 'has_kitchen_furniture',
  PetsAllowed = 'pets',
  Phone = 'phone',
  Pool = 'pool',
  Sauna = 'bathhouse',
  Security = 'security',
  Shower = 'shower',
  TV = 'tv',
  Washer = 'has_washer',
}

export enum ESuburbanUtility {
  Gas = 'gas',
  Electricity = 'electricity',
  Drainage = 'has_drainage',
  Water = 'has_water',
}

export enum EBuildingType {
  BusinessCenter = 1,
  ShoppingEntertainmentCenter = 2,
  OldFund = 5,
  OfficeBuilding = 6,
  BusinessPark = 7,
  OfficeIndustrialComplex = 8,
  OfficeWarehouseComplex = 9,
  WarehouseComplex = 10,
  IndustrialWarehouseComplex = 11,
  LogisticsCenter = 12,
  Warehouse = 13,
  AdministrativeBuilding = 14,
  IndustrialPark = 15,
  LogisticsComplex = 16,
  ShoppingBusinessComplex = 17,
  MultifunctionalComplex = 18,
  OfficeResidentialComplex = 19,
  TradingOfficeComplex = 20,
  OfficeHotelComplex = 21,
  BusinessCenter2 = 22,
  Mansion = 23,
  Technopark = 24,
  BusinessQuarter = 25,
  StandaloneBuilding = 26,
  ResidentialHouse = 27,
  ResidentialComplex = 28,
  ShoppingCenter = 29,
  SpecializedShoppingCenter = 30,
  ShoppingCommunityCenter = 31,
  Outlet = 32,
  IndustrialComplex = 33,
  IndustrialSite = 34,
  ManufacturingFacility = 35,
  FreeAppointmentObject = 36,
  PropertyComplex = 37,
  ManufactureBuilding = 38,
  ModularBuilding = 39,
  FreeBuilding = 40,
  OfficeWarehouse = 41,
  OfficeCenter = 42,
  OfficeQuarter = 43,
  BusinessQuarter2 = 44,
  BusinessHouse = 45,
  TradingHouse = 46,
  Other = 100,
}

export enum EPropertyType {
  Office = 1,
  Warehouse = 2,
  TradeArea = 3,
  Manufacture = 4,
  Building = 5,
  FreeAppointmentObject = 6,
  Land = 7,
  Garage = 8,
}

export enum EIsInKp {
  Any = 0,
  InKp = 1,
}

/**
 * @enum
 * @description Тип цены
 */
export enum EJsonQueryPriceType {
  /**
   * @type {EJsonQueryPriceType.Workplace}
   * @description Цена за рабочее место
   */
  Workplace = 'workplace',
}

/**
 * @enum
 * @description Тип бизнеса
 */
export enum EJsonQueryReadyBusinessType {
  /**
   * @type {EJsonQueryReadyBusinessType.RentalBusiness}
   * @description Арендный бизнес
   */
  RentalBusiness = 1,
  /**
   * @type {EJsonQueryReadyBusinessType.ReadyBusiness}
   * @description Готовый бизнес
   */
  ReadyBusiness = 2,
}

/**
 * @enum
 * @description Тип каталога
 */
export enum EJsonQueryListingType {
  /**
   * @type {EJsonQueryListingType.Newbuilding}
   * @description Выдача схлопнутая по ЖК
   */
  Newbuilding = 'newbuildings',
  /**
   * @type {EJsonQueryListingType.Offers}
   * @description Выдача объявлений
   */
  Offer = 'offers',
}
