import { FOfferType } from '../../../JsonQuery';
import { IOfferTypeSelectOption, IOfferTypeSelectOptionsGroup } from '../components/OfferTypeSelect/types';

export const getSaleResidential = (isSearchFlatShareEnabled: boolean): IOfferTypeSelectOptionsGroup[] => [
  {
    label: 'Квартиры и комнаты',
    options: [
      { label: 'Квартира', value: FOfferType.Flat },
      { label: 'Комната', value: FOfferType.Room },
      !isSearchFlatShareEnabled && { label: 'Доля', value: FOfferType.FlatShared },
    ].filter(Boolean) as IOfferTypeSelectOption[],
  },
  {
    label: 'Дома и участки',
    options: [
      { label: 'Дом, дача', value: FOfferType.House },
      { label: 'Часть дома', value: FOfferType.HousePart },
      { label: 'Таунхаус', value: FOfferType.Townhouse },
      { label: 'Участок', value: FOfferType.Land },
    ],
  },
  { label: 'Гаражи', options: [{ label: 'Гараж', value: FOfferType.Garage }] },
];
