import { ECommercialMetaActionType } from './types';
import { fetchCommercialMetaCachedSafe } from '../../services/fetchCommercialMeta';
import { TThunkAction } from '../../types/redux';
import { ISearchMeta } from '../../types/searchMeta';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const setStatusLoading = actionGenerator<ECommercialMetaActionType.Loading>(ECommercialMetaActionType.Loading);
const setStatusSucceed = actionGenerator<ECommercialMetaActionType.Succeed, ISearchMeta | null>(
  ECommercialMetaActionType.Succeed,
);
const setStatusFailed = actionGenerator<ECommercialMetaActionType.Failed>(ECommercialMetaActionType.Failed);

export function updateCommercialMeta(): TThunkAction<Promise<ISearchMeta | null>> {
  return async (dispatch, getState, context) => {
    const {
      filters: { jsonQuery },
    } = getState();

    dispatch(setStatusLoading());

    try {
      const searchMeta = await fetchCommercialMetaCachedSafe(context, { jsonQuery });
      dispatch(setStatusSucceed(searchMeta));

      return searchMeta;
    } catch (ex) {
      const { logger } = context;

      logger.error(ex);

      dispatch(setStatusFailed());

      return null;
    }
  };
}
