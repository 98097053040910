import * as React from 'react';

import { useDailyrentDefaultUrl } from '../../../../../../shared/hooks/useDailyrentDefaultUrl';
import { FDealType } from '../../../../../JsonQuery';
import { QuickFilterButton } from '../../../components/QuickFilters/QuickFilterButton';
import { RootDealTypeModal, getDealTypeLabel } from '../../../components/filters/RootDealTypeModal';
import { EQuickFilterType } from '../../../types/quickFilter';
import { getDailyrentUrl } from '../../../utils/getDailyrentUrl';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';

export const RootDealTypeContainer: React.FC = () => {
  const { onChange } = useContext();
  const dealType = useDealType();
  const dailyrentCleanUrl = useDailyrentDefaultUrl();

  const [modalOpened, setModalOpened] = React.useState(false);

  const label = React.useMemo(() => getDealTypeLabel(dealType), [dealType]);

  const handleChange = React.useCallback(
    (dealType: FDealType) => {
      setModalOpened(false);

      if (dealType === FDealType.RentDaily) {
        window.location.href = getDailyrentUrl(window.location.href, dailyrentCleanUrl);

        return;
      }

      onChange({ action: 'setDealType', arguments: [dealType] });
    },
    [onChange],
  );

  const handleClick = React.useCallback(() => setModalOpened(true), []);
  const handleClose = React.useCallback(() => setModalOpened(false), []);

  return (
    <>
      <QuickFilterButton text={label} onClick={handleClick} type={EQuickFilterType.Advanced} />
      <RootDealTypeModal value={dealType} onChange={handleChange} open={modalOpened} onClose={handleClose} />
    </>
  );
};
