import { getGlobalEventsLogs } from '@cian/events-log';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackJsonQueryChanged } from './tracking';
import { IOnChangeParameters, Provider, QuickFilters as QuickFiltersComponent } from '../../../packages/QuickFilters';
import { changeQuickFiltersJsonQuery } from '../../actions/filters';
import { selectJsonQuery } from '../../selectors/jsonQuery';
import { TThunkDispatch } from '../../types/redux';
import { useApplicationContext } from '../../utils/applicationContext';

const TOPIC_FILTERS = 'filters';

export const QuickFiltersContainer: React.FC = () => {
  const { logger, config, httpApi } = useApplicationContext();
  const dispatch = useDispatch<TThunkDispatch>();
  const jsonQuery = useSelector(selectJsonQuery);

  const handleChange = React.useCallback(
    async ({ nextJsonQuery, appliedModifiers }: IOnChangeParameters) => {
      const globalLog = getGlobalEventsLogs();

      globalLog.produce(TOPIC_FILTERS, {
        type: 'setLoading',
        value: true,
      });

      try {
        const searchMeta = await dispatch(
          changeQuickFiltersJsonQuery({ prevJsonQuery: jsonQuery, appliedModifiers, nextJsonQuery }),
        );

        globalLog.produce(TOPIC_FILTERS, {
          type: 'setLoading',
          value: false,
        });

        if (!searchMeta) {
          return;
        }

        globalLog.produce(TOPIC_FILTERS, {
          type: 'setJsonQuery',
          value: {
            jsonQuery: nextJsonQuery,
            queryString: searchMeta.queryString,
          } as object,
        });

        trackJsonQueryChanged(searchMeta.queryString, appliedModifiers[0].action);
      } catch (error) {
        logger.error(error, {
          domain: 'QuickFiltersContainer',
          message: 'Не удалось поменять быстрые фильтры',
        });

        globalLog.produce(TOPIC_FILTERS, {
          type: 'setLoading',
          value: false,
        });
      }
    },
    [dispatch, jsonQuery, logger],
  );

  const onFiltersButtonClick = React.useCallback(() => {
    const globalLog = getGlobalEventsLogs();
    globalLog.produce(TOPIC_FILTERS, {
      type: 'openFilters',
      value: true,
      ttl: 1,
    });
  }, []);

  return (
    <Provider
      onChange={handleChange}
      logger={logger}
      config={config}
      httpApi={httpApi}
      jsonQuery={jsonQuery}
      onFiltersButtonClick={onFiltersButtonClick}
    >
      <QuickFiltersComponent />
    </Provider>
  );
};
