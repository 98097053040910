import { exportsModule } from '@cian/mf-registry/browser';

import { buildAPIRuntime } from './api';
import { createCustomElementConstructor } from './createCustomElementConstructor';
import { init as initAuthContext } from '../../shared/services/authContext';
import { init as initEvents } from '../../shared/services/events';
import { createApplicationState } from '../services/applicationState';
import { createContext } from '../services/createContext';

export function start() {
  const context = createContext();
  const store = createApplicationState(context);

  initAuthContext(context, store);
  initEvents(context, store, false);

  exportsModule({
    ui: {
      '/public/v1/get-quick-filters-microfrontend/': () => ({
        customElementConstructor: createCustomElementConstructor(context, store),
      }),
    },
    api: () => buildAPIRuntime(store),
  });
}
