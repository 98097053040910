import * as R from 'ramda';

import { DEFAULT_LONGTERM_RENT_OPTION } from './constants';
import { setRootType, setTerm, setTerms, resetTerms } from './helpers';
import { setOfferType } from './setOfferType';
import { FDealType, FOfferType, TJsonQuery } from './types';
import { offerTypeFromJsonQuery } from './utils';
import { IJsonQueryType, ECategoryType, ERentTime } from '../api-models/common/json_query';

const FDealTypeName = new Map<FDealType, string>([
  [FDealType.Sale, 'sale'],
  [FDealType.Rent, 'rent'],
  [FDealType.RentLongterm, 'rent'],
  [FDealType.RentDaily, 'rent'],
]);

const FCategoryMap = new Map<FDealType, ECategoryType>([
  [FDealType.RentLongterm, ECategoryType.CommercialLandRent],
  [FDealType.Sale, ECategoryType.CommercialLandSale],
]);

export function setDealType(jsonQuery: TJsonQuery) {
  return (nextDealType: FDealType): TJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    // в посутке есть возможность выбрать эти фильтры, при переходе между вертикалями они должны затираться
    nextJsonQuery = resetTerms(['bath', 'shower'])(nextJsonQuery);

    {
      let nextRootType: IJsonQueryType = jsonQuery._type;
      const nextDealTypeString = FDealTypeName.get(nextDealType);
      if (nextDealTypeString) {
        nextRootType = nextRootType.replace(/rent|sale/, nextDealTypeString) as IJsonQueryType;
      }
      nextJsonQuery = setRootType(nextRootType)(nextJsonQuery);
    }

    /**
     * Если следующий тип сделки не поддерживает текущий тип предложения, переключаем на поддерживаемый
     */
    {
      const offerType = offerTypeFromJsonQuery(jsonQuery);
      const nextOfferType = resetNextOfferType(nextDealType, offerType);
      if (nextOfferType) {
        nextJsonQuery = setOfferType(nextJsonQuery)(nextOfferType);
      }
    }

    {
      const { category } = nextJsonQuery;

      nextJsonQuery = resetTerms(['category', 'for_day'])(nextJsonQuery);

      if ((nextDealType & FDealType.Rent) !== 0) {
        if (nextDealType === FDealType.RentDaily) {
          nextJsonQuery = setTerm('for_day')(nextJsonQuery)(ERentTime.Daily);
        } else {
          if (category) {
            nextJsonQuery = setTerms('category')(nextJsonQuery)([FCategoryMap.get(nextDealType) as ECategoryType]);
          }
          nextJsonQuery = setTerm('for_day')(nextJsonQuery)(DEFAULT_LONGTERM_RENT_OPTION);
        }
      }
    }

    return nextJsonQuery;
  };
}

function resetNextOfferType(nextDealType: FDealType, offerType: FOfferType): FOfferType | null {
  switch (nextDealType) {
    case FDealType.RentLongterm:
      if (offerType & FOfferType.Land) {
        return offerType & ~FOfferType.Land || FOfferType.Suburban & ~FOfferType.Land;
      }

      if (offerType & (FOfferType.FlatNew | FOfferType.FlatShared)) {
        return FOfferType.Flat;
      }
      break;
    case FDealType.RentDaily:
      if (offerType & FOfferType.Suburban) {
        return FOfferType.House;
      }

      if (offerType & (FOfferType.Any & ~FOfferType.FlatOld & ~FOfferType.Room & ~FOfferType.Bed & ~FOfferType.House)) {
        return FOfferType.Flat;
      }
      break;
    case FDealType.Sale:
      if (offerType & (FOfferType.FlatOld | FOfferType.Bed)) {
        return FOfferType.Flat;
      }
      break;
  }

  return null;
}
