import { TPromoSearchTypes } from '../promoSearchTypes';

export enum EPromoSearchTypesStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface IPromoSearchTypesState {
  status: EPromoSearchTypesStatus;
  items: TPromoSearchTypes | null;
}
