import { userAuthenticated } from '../../actions/authentication';
import { IApplicationContext } from '../../types/applicationContext';
import { IReduxStore, TThunkDispatch } from '../../types/redux';
import { lazyLoad } from '../../utils/authenticationWidget';

import type { IAuthenticationContext } from '@cian/authentication-widget';

let authContextProvider: AuthContextProvider | null = null;

export enum EStatus {
  Initial,
  Loading,
  Loaded,
  Error,
}

class AuthContextProvider {
  private readonly applicationContext: IApplicationContext;
  private readonly store: IReduxStore;
  private authContext: IAuthenticationContext | null;
  private authContextStatus: EStatus = EStatus.Initial;
  private lib: Awaited<ReturnType<typeof lazyLoad>>;

  public constructor(applicationContext: IApplicationContext, store: IReduxStore) {
    this.applicationContext = applicationContext;
    this.store = store;

    this.initLazy();
  }

  private initLazy = () => {
    if (this.shouldInitAuthContext()) {
      this.init().catch();
    }
  };

  public init = async () => {
    if (this.authContext) {
      return;
    }

    this.authContextStatus = EStatus.Loading;
    try {
      this.lib = await lazyLoad();
      this.initContext();
      this.authContextStatus = EStatus.Loaded;
    } catch (e) {
      this.applicationContext.logger.warning(e, {
        domain: 'authContext.init()',
        message: 'Failed to init authentication context',
      });
      this.authContextStatus = EStatus.Error;
      throw e;
    }
  };

  public get context() {
    return this.authContext;
  }

  public get status() {
    return this.authContextStatus;
  }

  private initContext = () => {
    const state = this.store.getState();
    const {
      config,
      httpApi,
      logger,
      custom: { subdomain },
    } = this.applicationContext;
    const { createContext, EEventType, EActiveView } = this.lib;
    const isRememberAccountsFlow = config.getStrict<boolean>('auth.rememberAccountsFlow.enabled');

    this.authContext = createContext(
      {
        httpApi,
        logger,
        config,
        subdomain,
      },
      {
        view: {
          active: isRememberAccountsFlow ? EActiveView.RememberedAccountsSelect : EActiveView.PhoneAuth,
          data: state.authentication.viewData,
        },
        isRememberAccountsFlow,
      },
    );

    this.authContext.on(EEventType.AuthenticateSucceed, this.handleAuthenticationSucceed);
    this.authContext.on(EEventType.SocialAuthenticateSucceed, this.handleSocialAuthenticateSucceed);
    this.authContext.on(EEventType.RegisterSucceed, this.handleRegisterSucceed);
    this.authContext.on(EEventType.EmailSentClosed, this.handleEmailSentClosed);
  };

  private shouldInitAuthContext = () => {
    const state = this.store.getState();

    return !state.user.isAuthenticated && this.applicationContext.custom.initialDeviceType === 'desktop';
  };

  private handleAuthenticationSucceed = () => {
    (this.store.dispatch as TThunkDispatch)(userAuthenticated());
  };

  private handleSocialAuthenticateSucceed = (event?: { isNewUser: boolean }) => {
    if (event && !event.isNewUser) {
      (this.store.dispatch as TThunkDispatch)(userAuthenticated());
    }
  };

  private handleRegisterSucceed = (event?: { senderType: string }) => {
    if (event && event.senderType) {
      (this.store.dispatch as TThunkDispatch)(userAuthenticated());
    }
  };

  private handleEmailSentClosed = (event?: { type: string }) => {
    if (event && event.type === 'registration') {
      window.location.reload();
    }
  };
}

export function init(context: IApplicationContext, store: IReduxStore) {
  if (!authContextProvider) {
    authContextProvider = new AuthContextProvider(context, store);
  }

  return authContextProvider;
}

export function getAuthContextProvider(): AuthContextProvider {
  if (!authContextProvider) {
    throw Error('AuthenticationContext is not initialized');
  }

  return authContextProvider;
}
