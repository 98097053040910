import { TTag } from '../tags';

export enum EGeoTagsStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface IGeoTagsState {
  status: EGeoTagsStatus;
  items: TTag[] | null;
}
