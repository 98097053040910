import { modalClose } from './modalClose';
import { getEvents } from '../../services/events';
import { TThunkAction } from '../../types/redux';

export const userAuthenticated = (): TThunkAction => {
  return (dispatch, getState) => {
    const {
      authentication: { autoRefresh },
    } = getState();

    getEvents().userAuthenticated();

    if (autoRefresh) {
      window.location.reload();
    } else {
      dispatch(modalClose(true));
    }
  };
};
