import { Button, ModalWindow as ModalWindowComponent, UIText1 } from '@cian/ui-kit';
import { IconFileTrash16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './ModalWindow.css';

export interface IModalWindowProps {
  open: boolean;
  onChange(): void;
  onClose(): void;
  onReset?(): void;
  children: React.ReactNode;
  title: string;
}

export const ModalWindow: React.FC<IModalWindowProps> = ({ children, open, onClose, onReset, onChange, title }) => (
  <ModalWindowComponent
    open={open}
    onClose={onClose}
    fixed
    width={952}
    title={title}
    footer={
      <div className={styles['apply-button']}>
        <Button theme="fill_primary" fullWidth onClick={onChange} size="L">
          Применить
        </Button>
      </div>
    }
  >
    <div className={styles['container']}>
      {children}
      {onReset && (
        <button className={styles['reset-button']} onClick={onReset}>
          <IconFileTrash16 color="icon-main-default" />
          <UIText1 color="text-main-default">Очистить</UIText1>
        </button>
      )}
    </div>
  </ModalWindowComponent>
);
