import { applyMiddleware, CombinedState, combineReducers, compose, createStore as _createStore } from 'redux';
import thunk from 'redux-thunk';

import { authenticationReducer } from '../../reducers/authentication';
import { commercialMetaReducer } from '../../reducers/commercialMeta';
import { experimentsReducer } from '../../reducers/experiments';
import { jsonQueryReducer, geoTagsReducer, promoSearchTypesReducer } from '../../reducers/filters';
import { datesModalReducer } from '../../reducers/filters/datesModalReducer';
import { villageMortgageFiltedEnabledReducer } from '../../reducers/filters/villageMortgageFilterEnabled';
import { pageReducer } from '../../reducers/page';
import { regionMetaReducer } from '../../reducers/regionMeta';
import { regionalDistrictsReducer } from '../../reducers/regionalDistricts';
import { regionsReducer } from '../../reducers/regions';
import { searchMetaReducer } from '../../reducers/searchMeta';
import { specialPromosReducer } from '../../reducers/specialPromos';
import { userReducer } from '../../reducers/user';
import { IApplicationContext } from '../../types/applicationContext';
import { IFiltersState } from '../../types/filters';
import { IApplicationState, TReduxActions, IReduxStore, TThunkDispatch } from '../../types/redux';

interface IThunkExt {
  dispatch: TThunkDispatch;
}

let storeReminder: IReduxStore;

/**
 * Хелпер для создания Redux стора
 * @param initialState IApplicationState - предзагруженное состояние
 */
export function createReduxStore(context: IApplicationContext, initialState: IApplicationState, debugEnabled: boolean) {
  const reducers = combineReducers<IApplicationState, TReduxActions>({
    commercialMeta: commercialMetaReducer,
    filters: combineReducers<IFiltersState, TReduxActions>({
      jsonQuery: jsonQueryReducer,
      geoTags: geoTagsReducer,
      isVillageMortgageFilterEnabled: villageMortgageFiltedEnabledReducer,
      datesModal: datesModalReducer,
      promoSearchTypes: promoSearchTypesReducer,
    }),
    page: pageReducer,
    regions: regionsReducer,
    regionMeta: regionMetaReducer,
    searchMeta: searchMetaReducer,
    specialPromos: specialPromosReducer,
    user: userReducer,
    experiments: experimentsReducer,
    regionalDistricts: regionalDistrictsReducer,
    authentication: authenticationReducer,
  });

  const composeEnhancers = debugEnabled ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

  const storeEnhancers = composeEnhancers(applyMiddleware(thunk.withExtraArgument(context)));

  const createStore = (): IReduxStore =>
    _createStore<CombinedState<IApplicationState>, TReduxActions, IThunkExt, {}>(
      reducers,
      initialState,
      storeEnhancers,
    );

  if (process.env.NODE_ENV === 'development' && debugEnabled) {
    if (!storeReminder) {
      storeReminder = createStore();
    }

    storeReminder.replaceReducer(reducers);

    return storeReminder;
  }

  return createStore();
}
