import { apartments } from './apartments';
import { dealType } from './dealType';
import { demolition } from './demolition';
import { flatRooms } from './flatRooms';
import { flatType } from './flatType';
import { price } from './price';
import { roomType } from './roomType';
import { rootDealType } from './rootDealType';
import { rootOfferType } from './rootOfferType';
import { IFilter, TFilter } from '../../types/filters';

export const FILTERS: { [key in TFilter]: IFilter } = {
  apartments,
  dealType,
  demolition,
  flatRooms,
  flatType,
  price,
  roomType,
  rootDealType,
  rootOfferType,
};
