import { createConsumer, createSubscriber, getGlobalEventsLogs, IEvent, TSubscriberHandler } from '@cian/events-log';
import { ConfigurationError } from '@cian/peperrors/shared';

import { IAuthenticateEvent } from './types';
import { modalOpen } from '../../actions/authentication';
import { IApplicationContext } from '../../types/applicationContext';
import { IReduxStore } from '../../types/redux';

const TOPIC_USER = 'user';

let events: Events | null;

class Events {
  private globalLog = getGlobalEventsLogs();
  private store: IReduxStore;
  private withEventsAPI: boolean;

  public constructor(context: IApplicationContext, store: IReduxStore, withEventsAPI: boolean) {
    this.store = store;

    this.withEventsAPI = withEventsAPI;

    const userConsumer = createConsumer({ topic: TOPIC_USER });

    const userSubscriber = createSubscriber(this.userTopicHandler as TSubscriberHandler);

    userConsumer.subscribe(userSubscriber);
  }

  private userTopicHandler = (event: IEvent<IAuthenticateEvent>) => {
    if (this.withEventsAPI && event.type === 'authenticate') {
      const { autoRefresh = true, alert = null, useDefaultView = false, viewData = null, source = null } = event.value;

      this.store.dispatch(modalOpen({ autoRefresh, alert, useDefaultView, viewData, source }));
    }
  };

  public userAuthenticated = () => {
    this.globalLog.produce(TOPIC_USER, { type: 'authenticated', value: null });
  };

  public authModalClosedNoAuth = () => {
    this.globalLog.produce(TOPIC_USER, {
      type: 'authentication_modal_closed',
      value: null,
    });
  };
}

export function init(context: IApplicationContext, store: IReduxStore, withEventsAPI = true) {
  if (!events) {
    events = new Events(context, store, withEventsAPI);
  }

  return events;
}

export function getEvents() {
  if (!events) {
    throw new ConfigurationError({
      message: 'Events must be created before accessing it. Forgot to call init() before accessing events?',
      domain: 'events.getEvents',
    });
  }

  return events;
}
