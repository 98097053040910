import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { ERoomType } from '../../../../../api-models/common/json_query';
import { IModalWindowProps, ModalWindow } from '../../ModalWindow';

interface IRoomType {
  label: string;
  value: ERoomType;
}

const ROOM_TYPES: IRoomType[] = [
  { label: '1', value: ERoomType.FlatOneRoom },
  { label: '2', value: ERoomType.FlatTwoRooms },
  { label: '3', value: ERoomType.FlatThreeRooms },
  { label: '4', value: ERoomType.FlatFourRooms },
  { label: '5', value: ERoomType.FlatFiveRooms },
  { label: '6+', value: ERoomType.FlatManyRooms },
  { label: 'Студия', value: ERoomType.Studio },
  { label: 'Свободная планировка', value: ERoomType.FlatFreeLayout },
];

interface IRoomTypeProps extends Omit<IModalWindowProps, 'onChange' | 'children' | 'title'> {
  value: NonEmptyArray<ERoomType> | null;
  onChange(value: NonEmptyArray<ERoomType> | null): void;
}

export const RoomTypeModal: React.FC<IRoomTypeProps> = ({ value: initialValue, onChange, ...modalProps }) => {
  const [value, setValue] = React.useState<NonEmptyArray<ERoomType> | null>(null);

  React.useEffect(() => setValue(initialValue), [initialValue]);

  const onChangeCheckbox = React.useCallback((value: NonEmptyArray<ERoomType> | null) => setValue(value), []);
  const handleChange = React.useCallback(() => onChange(value), [value, onChange]);
  const onReset = React.useCallback(() => {
    setValue(null);
    onChange(null);
  }, [onChange]);

  return (
    <ModalWindow title="Количество комнат" onChange={handleChange} onReset={onReset} {...modalProps}>
      <CheckboxButtonGroup options={ROOM_TYPES} value={value} onChange={onChangeCheckbox} />
    </ModalWindow>
  );
};
