import { everyOf, FDealType, FOfferType, isAvailable, isAvailableRegion } from '../../../../JsonQuery';
import { DemolitionContainer } from '../../containers/filters/DemolitionContainer';
import { IFilter } from '../../types/filters';

export const MOSCOW_ID = 1;

export const demolition: IFilter = {
  availability: everyOf(isAvailableRegion([MOSCOW_ID]), isAvailable(FDealType.Any, FOfferType.Urban & ~FOfferType.Bed)),
  component: DemolitionContainer,
  priority: 1,
};
