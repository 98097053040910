import { ISearchMeta } from '../searchMeta';

export enum ECommercialMetaStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface ICommercialMetaState {
  status: ECommercialMetaStatus;
  meta: ISearchMeta | null;
}
