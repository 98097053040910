import { EVillageMortgageActionType, TFiltersActions } from '../../actions/filters';

export const defaultState = false;

export function villageMortgageFiltedEnabledReducer(state = defaultState, action: TFiltersActions): boolean {
  switch (action.type) {
    case EVillageMortgageActionType.Enable:
      return true;
    case EVillageMortgageActionType.Disable:
      return false;
    default:
      return state;
  }
}
