import { IGetMetaResponse } from '../../repositories/search-offers-index/v1/get-meta';
import { ISearchMeta } from '../../types/searchMeta';

export function prepareSearchMeta(response: IGetMetaResponse): ISearchMeta {
  return {
    count: response.count,
    queryString: response.querystring,
    seoPath: response.seoPath ? response.seoPath.replace(/\//g, '') : null,
    subdomain: response.subdomain,
    placeholders: response.placeholders ?? {},
  };
}
