import { ITypedReduxAction } from '../../types/redux/actionType';
import { ISearchMeta } from '../../types/searchMeta';

export enum ECommercialMetaActionType {
  Loading = 'commercialMeta/updateCommercialMeta/loading',
  Succeed = 'commercialMeta/updateCommercialMeta/succeed',
  Failed = 'commercialMeta/updateCommercialMeta/failed',
}

export type TUpdateCommercialMetaLoading = ITypedReduxAction<ECommercialMetaActionType.Loading>;
export type TUpdateCommercialMetaSucceed = ITypedReduxAction<ECommercialMetaActionType.Succeed, ISearchMeta | null>;
export type TUpdateCommercialMetaFailed = ITypedReduxAction<ECommercialMetaActionType.Failed>;

export type TCommercialMetaActions =
  | TUpdateCommercialMetaLoading
  | TUpdateCommercialMetaSucceed
  | TUpdateCommercialMetaFailed;
