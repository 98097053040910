import { SHRUG } from '../../../constants/shrug';
import { commarize, deunit, getUnit, round } from '../../../utils/number';

export function getPriceLabel(min: number | null, max: number | null, isSquareMeterPrice: boolean | null) {
  let priceMin: string | null = null;
  const priceMax: string | null = max !== null ? commarize(max) : null;

  if (min !== null && max !== null) {
    if (min === 0 && max === 0) {
      return SHRUG;
    }

    const minUnit = getUnit(min);
    const maxUnit = getUnit(max);
    const minWithUnit = min > max || minUnit !== maxUnit;

    const minDeunited = deunit(min, minUnit);

    if (minDeunited >= 100 && maxUnit - minUnit === 1) {
      priceMin = round(minDeunited / 1000, 1)
        .toString()
        .replace(/\./g, ',');
    } else {
      priceMin = commarize(min, !minWithUnit);
    }
  } else if (min !== null) {
    priceMin = commarize(min);
  }

  const text = priceText(priceMin, priceMax);

  if (!text) {
    return null;
  }

  return `${text}${isSquareMeterPrice ? '/м\u00B2' : ''}`;
}

function priceText(priceMin: string | null, priceMax: string | null) {
  if (priceMin && !priceMax) {
    return `от ${priceMin}`;
  }

  if (!priceMin && priceMax) {
    return `до ${priceMax}`;
  }

  if (priceMin && priceMax) {
    return `${priceMin} - ${priceMax}`;
  }

  return null;
}
