import { IConfig } from '@cian/config/shared';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger';
import * as React from 'react';

import { NonEmptyArray } from '../../../Fields';
import { TJsonQuery } from '../../../JsonQuery';
import { TModifier } from '../types/modifier';

export interface IContext {
  logger: ILogger;
  config: IConfig;
  httpApi: IHttpApi;
  jsonQuery: TJsonQuery;
  onFiltersButtonClick(): void;
  onChange(...modifiers: NonEmptyArray<TModifier>): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const context = React.createContext<IContext>(null as any);
