import * as R from 'ramda';

import { getGeoValue, setGeo } from './helpers';
import { TJsonQuery } from './types';

export function clearRegionalDistricts(jsonQuery: TJsonQuery): () => TJsonQuery {
  return () => {
    const geoValues = getGeoValue()(jsonQuery);

    const regionalDistricts = geoValues?.filter(({ type }) => type === 'regional_district_id');

    if (!geoValues || !regionalDistricts?.length) {
      return jsonQuery;
    }

    return setGeo()(jsonQuery)(geoValues.filter(value => !R.includes(value, regionalDistricts)));
  };
}
