import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { ERoomsTotal } from '../../../../../api-models/common/json_query';
import { IModalWindowProps, ModalWindow } from '../../ModalWindow';

interface IRoom {
  label: string;
  value: ERoomsTotal;
}

const ROOMS: IRoom[] = [
  { label: '1', value: ERoomsTotal.One },
  { label: '2', value: ERoomsTotal.Two },
  { label: '3', value: ERoomsTotal.Three },
  { label: '4', value: ERoomsTotal.Four },
  { label: '5', value: ERoomsTotal.Five },
];

interface IFlatRoomsProps extends Omit<IModalWindowProps, 'onChange' | 'children' | 'title'> {
  value: NonEmptyArray<ERoomsTotal> | null;
  onChange(value: NonEmptyArray<ERoomsTotal> | null): void;
}

export const FlatRoomsModal: React.FC<IFlatRoomsProps> = ({ value: initialValue, onChange, ...modalProps }) => {
  const [value, setValue] = React.useState<NonEmptyArray<ERoomsTotal> | null>(null);

  React.useEffect(() => setValue(initialValue), [initialValue]);

  const onChangeCheckbox = React.useCallback((value: NonEmptyArray<ERoomsTotal> | null) => setValue(value), []);
  const handleChange = React.useCallback(() => onChange(value), [value, onChange]);
  const onReset = React.useCallback(() => {
    setValue(null);
    onChange(null);
  }, [onChange]);

  return (
    <ModalWindow title="Количество комнат" onChange={handleChange} onReset={onReset} {...modalProps}>
      <CheckboxButtonGroup options={ROOMS} value={value} onChange={onChangeCheckbox} />
    </ModalWindow>
  );
};
