/* eslint-disable max-lines */
import { FILTERS_RESIDENTIAL_COMPLEXES, FILTERS_STATIC, FILTERS_SUBURBAN, FILTERS_URBAN } from './constants/filters';
import { FILTERS_GARAGES } from './constants/filters/garages';
import { IFilterList } from './types';
import { TJsonQuery } from '../../../../JsonQuery';
import { TFilter } from '../../types/filters';

const FILTERS_LIST: IFilterList[] = [
  ...FILTERS_RESIDENTIAL_COMPLEXES,
  ...FILTERS_URBAN,
  ...FILTERS_SUBURBAN,
  ...FILTERS_GARAGES,
];

interface IGetAvailableFiltersParams {
  jsonQuery: TJsonQuery;
}

export function getAvailableFilters({ jsonQuery }: IGetAvailableFiltersParams): TFilter[] {
  const type = FILTERS_LIST.find(f => f.availability(jsonQuery));

  /* istanbul ignore next */
  const availableFilters = [...FILTERS_STATIC, ...((type && type.list) || [])];

  return availableFilters;
}
