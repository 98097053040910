import { ITypedReduxAction } from '../../types/redux/actionType';
import { IRegionalDistrictsList } from '../../types/regionalDistricts';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum ERegionalDistrictsActionType {
  Changed = 'regionalDistricts/changed',
}

export type TChangedAction = ITypedReduxAction<ERegionalDistrictsActionType.Changed, IRegionalDistrictsList>;

export type TRegionalDistrictsActions = TChangedAction;

export const updateRegionalDistrictsRaw = actionGenerator<ERegionalDistrictsActionType.Changed, IRegionalDistrictsList>(
  ERegionalDistrictsActionType.Changed,
);
