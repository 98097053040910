import { EGeoTagsActionType } from './types';
import { fetchGeoTags } from '../../services/fetchGeoTags';
import { TThunkAction } from '../../types/redux';
import { TTag } from '../../types/tags';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const setStatusLoading = actionGenerator<EGeoTagsActionType.Loading>(EGeoTagsActionType.Loading);
const setStatusSucceed = actionGenerator<EGeoTagsActionType.Succeed, TTag[] | null>(EGeoTagsActionType.Succeed);
const setStatusFailed = actionGenerator<EGeoTagsActionType.Failed>(EGeoTagsActionType.Failed);

export function updateTags(): TThunkAction<Promise<TTag[] | null>> {
  return async (dispatch, getState, context) => {
    dispatch(setStatusLoading());

    const {
      filters: { jsonQuery },
      searchMeta: { subdomain },
    } = getState();

    try {
      const geoTags = await fetchGeoTags(context, {
        jsonQuery,
        subdomain,
      });

      dispatch(setStatusSucceed(geoTags));

      return geoTags;
    } catch (ex) {
      const { logger } = context;

      logger.error(ex);

      dispatch(setStatusFailed());

      return null;
    }
  };
}
