import { EFiltersActionType, IJsonQueryChanged } from './types';
import { updatePromoSearchTypes } from './updatePromoSearchTypes';
import { updateTags } from './updateTags';
import { updateVillageMortgageFilterEnable } from './updateVillageMortgageFilterEnable';
import {
  FDealType,
  FOfferType,
  getRegionalDistrictsValue,
  isAvailable,
  isSuburban,
  JsonQuery,
  offerTypeFromJsonQuery,
} from '../../../packages/JsonQuery';
import { GEO_CHANGE_ACTIONS } from '../../constants/jsonQuery/geo';
import { TThunkAction } from '../../types/redux';
import { ISearchMeta } from '../../types/searchMeta';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { updateCommercialMeta } from '../commercialMeta';
import { updateRegionMeta } from '../regionMeta';
import { updateRegionalDistricts } from '../regionalDistricts';
import { updateSearchMeta } from '../searchMeta';

export const changeJsonQueryRaw = actionGenerator<EFiltersActionType.JsonQueryChanged, IJsonQueryChanged>(
  EFiltersActionType.JsonQueryChanged,
);

export function changeJsonQuery(payload: IJsonQueryChanged): TThunkAction<Promise<ISearchMeta | null>> {
  return async (dispatch, getState, context) => {
    const { nextJsonQuery, appliedModifiers } = payload;

    dispatch(changeJsonQueryRaw(payload));

    if (appliedModifiers.some(m => m.action === 'setRegion')) {
      await dispatch(updateRegionMeta());

      const isVillageMortgageFilterEnabled = !!context.config.get<boolean>('Countryside.VillageMortgage.Enabled');

      if (isVillageMortgageFilterEnabled && isAvailable(FDealType.Sale, FOfferType.Suburban)(nextJsonQuery)) {
        dispatch(updateVillageMortgageFilterEnable());
      }
    }

    if (appliedModifiers.some(m => m.action === 'setDealType' || m.action === 'setRegion')) {
      dispatch(updateCommercialMeta());
    }

    if (appliedModifiers.some(m => m.action === 'setOfferType' && isSuburban(m.arguments[0]))) {
      await dispatch(updateRegionMeta());

      const { regionMeta } = getState();

      /* istanbul ignore else */
      if (regionMeta.expansibleRegion) {
        dispatch(
          changeJsonQueryRaw({
            prevJsonQuery: nextJsonQuery,
            appliedModifiers: payload.appliedModifiers,
            nextJsonQuery: new JsonQuery(nextJsonQuery).setRegion([regionMeta.id]).toJSON(),
          }),
        );
      }
    }

    if (appliedModifiers.some(m => m.action === 'setOfferType' || m.action === 'setRegion')) {
      const regionalDistricts = getRegionalDistrictsValue()(nextJsonQuery);

      if (!isSuburban(offerTypeFromJsonQuery(nextJsonQuery)) && regionalDistricts?.length) {
        dispatch(
          changeJsonQueryRaw({
            prevJsonQuery: nextJsonQuery,
            appliedModifiers: payload.appliedModifiers,
            nextJsonQuery: new JsonQuery(nextJsonQuery).clearRegionalDistricts().toJSON(),
          }),
        );
      }

      dispatch(updateRegionalDistricts());
    }

    if (appliedModifiers.some(m => GEO_CHANGE_ACTIONS.includes(m.action))) {
      dispatch(updateTags());

      if (isAvailable(FDealType.Sale, FOfferType.FlatNew)(nextJsonQuery)) {
        dispatch(updatePromoSearchTypes());
      }
    }

    return dispatch(updateSearchMeta());
  };
}

export function changeQuickFiltersJsonQuery(payload: IJsonQueryChanged): TThunkAction<Promise<ISearchMeta | null>> {
  return async dispatch => {
    dispatch(changeJsonQueryRaw(payload));

    return dispatch(updateSearchMeta());
  };
}
