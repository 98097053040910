import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { QuickFilterButton } from '../../../components/QuickFilters/QuickFilterButton';
import { EQuickFilterType } from '../../../types/quickFilter';
import { useContext } from '../../../utils/useContext';

export const DemolitionContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const demolition = getTermValue('demolished_in_moscow_programm')(jsonQuery);

  const handleClick = React.useCallback(() => {
    onChange({ action: 'setDemolition', arguments: [null] });
  }, [onChange]);

  if (demolition === null) {
    return null;
  }

  return (
    <QuickFilterButton
      onClick={handleClick}
      text={demolition ? 'Дома под снос' : 'Без домов под снос'}
      type={EQuickFilterType.Single}
    />
  );
};
