import { getTermValue } from './helpers';
import { EFacilityType, NonEmptyArray, TJsonQuery } from './types';

export function getSuburbanFacilityTypes(jsonQuery: TJsonQuery): NonEmptyArray<EFacilityType> | null {
  const hasSauna = getTermValue('bathhouse')(jsonQuery);
  const hasGarage = getTermValue('garage')(jsonQuery);
  const hasSecurity = getTermValue('security')(jsonQuery);
  const hasPool = getTermValue('pool')(jsonQuery);
  const conditioner = getTermValue('conditioner')(jsonQuery);
  const hasFurniture = getTermValue('has_furniture')(jsonQuery);
  const hasKitchenFurniture = getTermValue('has_kitchen_furniture')(jsonQuery);
  const internet = getTermValue('internet')(jsonQuery);
  const hasFridge = getTermValue('has_fridge')(jsonQuery);
  const dishWasher = getTermValue('dish_washer')(jsonQuery);
  const tv = getTermValue('tv')(jsonQuery);
  const hasWasher = getTermValue('has_washer')(jsonQuery);

  const facilityTypes: EFacilityType[] = [];

  if (hasSauna) {
    facilityTypes.push(EFacilityType.Sauna);
  }

  if (hasGarage) {
    facilityTypes.push(EFacilityType.Garage);
  }

  if (hasSecurity) {
    facilityTypes.push(EFacilityType.Security);
  }

  if (hasPool) {
    facilityTypes.push(EFacilityType.Pool);
  }

  if (conditioner) {
    facilityTypes.push(EFacilityType.Conditioner);
  }

  if (hasFurniture) {
    facilityTypes.push(EFacilityType.RoomFurniture);
  }

  if (hasKitchenFurniture) {
    facilityTypes.push(EFacilityType.KitchenFurniture);
  }

  if (internet) {
    facilityTypes.push(EFacilityType.Internet);
  }

  if (hasFridge) {
    facilityTypes.push(EFacilityType.Fridge);
  }

  if (dishWasher) {
    facilityTypes.push(EFacilityType.Dishwasher);
  }

  if (tv) {
    facilityTypes.push(EFacilityType.TV);
  }

  if (hasWasher) {
    facilityTypes.push(EFacilityType.Washer);
  }

  return facilityTypes.length > 0 ? (facilityTypes as NonEmptyArray<EFacilityType>) : null;
}
