import { IconButton } from '@cian/ui-kit/button';
import { IconActionClose24 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { Header as HeaderComponent, IHeaderProps } from '../../../../../ModalWindow';

interface IHeaderBackableProps extends Omit<IHeaderProps, 'left'> {
  onClose(): void;
}

// istanbul ignore next
export const Header: React.FC<IHeaderBackableProps> = ({ onClose, ...sharedProps }) => {
  return (
    <HeaderComponent
      {...sharedProps}
      right={<IconButton icon={<IconActionClose24 display="block" />} onClick={onClose} />}
    />
  );
};
