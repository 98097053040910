import { EGetUserStatus } from '../../actions/user';
import { TReduxActions } from '../../types/redux';
import { TUser } from '../../types/user';

const defaultState: TUser = {
  isAuthenticated: false,
};

export function userReducer(state: TUser = defaultState, action: TReduxActions): TUser {
  switch (action.type) {
    case EGetUserStatus.Succeed: {
      return action.payload;
    }
  }

  return state;
}
