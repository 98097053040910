import * as React from 'react';

import { RadioButtonGroup } from '../../../../../Fields/shared/components/RadioButtonGroup';
import { FOfferType } from '../../../../../JsonQuery';
import { IModalWindowProps, ModalWindow } from '../../ModalWindow';

interface IFlatTypeOption {
  label: string;
  value: FOfferType;
}

const FLAT_TYPE_OPTIONS: IFlatTypeOption[] = [
  { label: 'Все', value: FOfferType.Flat },
  { label: 'Новостройка', value: FOfferType.FlatNew },
  { label: 'Вторичка', value: FOfferType.FlatOld },
];

export function getFlatTypeLabel(flatType: FOfferType, fullTitle = false) {
  if (fullTitle && flatType === FOfferType.Flat) {
    return 'Вторичка и новостройки';
  }

  return (FLAT_TYPE_OPTIONS.find(({ value }) => value === flatType) as IFlatTypeOption).label;
}

interface IFlatTypeModalProps extends Omit<IModalWindowProps, 'onChange' | 'children' | 'title'> {
  value: FOfferType;
  onChange(value: FOfferType): void;
}

export const FlatTypeModal: React.FC<IFlatTypeModalProps> = ({ value: initialValue, onChange, ...modalProps }) => {
  const [value, setValue] = React.useState(initialValue);

  const onChangeRadio = React.useCallback((value: FOfferType) => setValue(value), []);
  const handleChange = React.useCallback(() => onChange(value), [value, onChange]);

  return (
    <ModalWindow title="Вторичка и новостройки" onChange={handleChange} {...modalProps}>
      <RadioButtonGroup spliced={false} options={FLAT_TYPE_OPTIONS} value={value} onChange={onChangeRadio} />
    </ModalWindow>
  );
};
