import * as React from 'react';

import { PRICE_TYPES, getIsSquareMeterPrice, getPriceType } from './helpers';
import { FilterRow } from '../../../../Filters/shared/components/filters/common/Filter';
import { EPriceType, FDealType, FOfferType } from '../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../../api-models/common/json_query';
import { TFieldPlaceholders } from '../../types/placeholders';
import { useDebouncedCallback } from '../../utils/useDebouncedCallback';
import { RadioButtonGroup } from '../RadioButtonGroup';
import { RangeInput } from '../RangeInput';

export interface IPriceFieldProps {
  dealType: FDealType;
  offerType: FOfferType;
  price: IJsonQueryRangeValue | null;
  isSquareMeterPrice?: boolean | null;
  placeholders?: TFieldPlaceholders;
  onPriceMinChange(gte: number | null): void;
  onPriceMaxChange(lte: number | null): void;
  onPriceTypeChange?(isSquareMeterPrice: boolean | null): void;
}

export const PriceField: React.FC<IPriceFieldProps> = ({
  dealType,
  offerType,
  price,
  isSquareMeterPrice,
  placeholders,
  onPriceMinChange,
  onPriceMaxChange,
  onPriceTypeChange,
}) => {
  const { gte: priceMin = null, lte: priceMax = null } = price || {};
  const isPriceTypeAvailable = dealType === FDealType.Sale && (offerType & FOfferType.Urban) !== 0;

  const priceType = getPriceType(isSquareMeterPrice);

  const handlePriceMinChange = useDebouncedCallback((gte: number | null) => {
    onPriceMinChange(gte);
  }, 300);

  const handlePriceMaxChange = useDebouncedCallback((lte: number | null) => {
    onPriceMaxChange(lte);
  }, 300);

  return (
    <>
      <FilterRow>
        <RangeInput
          min={0}
          max={999999999999}
          joined={false}
          postfix={'\u20bd'}
          valueMin={priceMin}
          valueMax={priceMax}
          onChangeMin={handlePriceMinChange}
          onChangeMax={handlePriceMaxChange}
          {...placeholders}
        />
      </FilterRow>
      {isPriceTypeAvailable && onPriceTypeChange && (
        <FilterRow>
          <RadioButtonGroup
            options={PRICE_TYPES}
            value={priceType || EPriceType.Total}
            onChange={(value: EPriceType) => onPriceTypeChange(getIsSquareMeterPrice(value))}
          />
        </FilterRow>
      )}
    </>
  );
};
