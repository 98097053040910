import { ITypedReduxAction } from '../../types/redux/actionType';
import { TUser } from '../../types/user';

export enum EGetUserStatus {
  Initial = 'user/initial',
  Loading = 'user/loading',
  Succeed = 'user/succeed',
  Failed = 'user/failed',
}

export type TGetUserLoading = ITypedReduxAction<EGetUserStatus.Loading>;
export type TGetUserSucceed = ITypedReduxAction<EGetUserStatus.Succeed, TUser>;
export type TGetUserFailed = ITypedReduxAction<EGetUserStatus.Failed>;

export type TUserActions = TGetUserLoading | TGetUserSucceed | TGetUserFailed;
