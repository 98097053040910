import * as React from 'react';

import { getPriceLabel } from './getPriceLabel';
import { NonEmptyArray, getRangeValue, getTermValue } from '../../../../../JsonQuery';
import { QuickFilterButton } from '../../../components/QuickFilters/QuickFilterButton';
import { PriceModal } from '../../../components/filters/PriceModal';
import { TModifier } from '../../../types/modifier';
import { EQuickFilterType } from '../../../types/quickFilter';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';
import { useOfferType } from '../../../utils/useOfferType';

export const PriceContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const dealType = useDealType();
  const offerType = useOfferType();
  const price = getRangeValue('price')(jsonQuery);
  const isSquareMeterPrice = getTermValue('price_sm')(jsonQuery);

  const [modalOpened, setModalOpened] = React.useState(false);

  const handleChange = React.useCallback(
    (...modifiers: NonEmptyArray<TModifier>) => {
      onChange(...modifiers);
      setModalOpened(false);
    },
    [onChange],
  );

  const handleClick = React.useCallback(() => setModalOpened(true), []);
  const handleClose = React.useCallback(() => setModalOpened(false), []);

  const priceLabel = React.useMemo(() => {
    if (!price) {
      return 'Цена';
    }

    const { gte = null, lte = null } = price;

    return getPriceLabel(gte, lte, isSquareMeterPrice) || 'Цена';
  }, [price, isSquareMeterPrice]);

  return (
    <>
      <QuickFilterButton onClick={handleClick} text={priceLabel} type={EQuickFilterType.Advanced} />
      <PriceModal
        open={modalOpened}
        onClose={handleClose}
        onChange={handleChange}
        dealType={dealType}
        offerType={offerType}
        price={price}
        isSquareMeterPrice={isSquareMeterPrice}
      />
    </>
  );
};
