import * as R from 'ramda';

import { setGeo } from './helpers';
import { NonEmptyArray, TJsonQuery } from './types';
import { IJsonQueryRegionalDistrict } from '../api-models/common/json_query';

export function setRegionalDistricts(
  jsonQuery: TJsonQuery,
): (...geoDistricts: NonEmptyArray<IJsonQueryRegionalDistrict>) => TJsonQuery {
  return (...geoDistricts) => {
    const geo = (jsonQuery.geo && R.clone(jsonQuery.geo.value)) || [];

    const nextGeo = geo.filter(g => g.type !== 'regional_district_id' || geoDistricts.some(d => d.id === g.id));
    const addedGeoDistricts = geoDistricts.filter(d => !geo.some(g => R.equals(d, g)));

    return setGeo()(jsonQuery)([...nextGeo, ...addedGeoDistricts]);
  };
}
