import { ErrorLogComponent } from '@cian/error-log-component';
import { UiKitRoot } from '@cian/ui-kit';
import * as React from 'react';
import { Provider as StoreProvider } from 'react-redux';

import { IMicrofrontendApplicationProps } from '../../types/microfrontendApplicationProps';
import { ApplicationContext } from '../../utils/applicationContext';
import { QuickFiltersContainer } from '../QuickFiltersContainer';

export const Application: React.FC<IMicrofrontendApplicationProps> = ({ reduxStore, context }) => {
  const {
    logger,
    custom: { initialDeviceType },
  } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitRoot deviceType={initialDeviceType}>
          <StoreProvider store={reduxStore}>
            <QuickFiltersContainer />
          </StoreProvider>
        </UiKitRoot>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
