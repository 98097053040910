import { Button } from '@cian/ui-kit';
import { IconActionFilters16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './QuickFilters.css';

interface IQuickFiltersProps {
  children: React.ReactNode;
  onFiltersButtonClick(): void;
}

export const QuickFilters: React.FC<IQuickFiltersProps> = ({ children, onFiltersButtonClick }) => {
  return (
    <div className={styles['container']}>
      <Button
        data-name="AllFilltersButton"
        beforeIcon={<IconActionFilters16 color="icon-main-default" />}
        theme="fill_secondary"
        onClick={onFiltersButtonClick}
      />
      {children}
    </div>
  );
};
