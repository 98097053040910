import { changeJsonQuery } from './changeJsonQuery';
import { EVillageMortgageActionType } from './types';
import { JsonQuery } from '../../../packages/JsonQuery';
import { fetchSearchMeta } from '../../services/fetchSearchMeta';
import { TThunkAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const enableVillageMortgageFilter = actionGenerator<EVillageMortgageActionType.Enable>(
  EVillageMortgageActionType.Enable,
);

const disableVillageMortgageFilter = actionGenerator<EVillageMortgageActionType.Disable>(
  EVillageMortgageActionType.Disable,
);

export function updateVillageMortgageFilterEnable(): TThunkAction<Promise<boolean>> {
  return async (dispatch, getState, context) => {
    const {
      filters: { jsonQuery },
    } = getState();

    const modifiedJsonQuery = new JsonQuery(jsonQuery).setVillageMortgageAllowed(true).toJSON();

    try {
      const searchMeta = await fetchSearchMeta(context, {
        jsonQuery: modifiedJsonQuery,
        cancellationTokenKey: 'fetchSearchMetaForVillageMortgage',
      });

      if (searchMeta.count) {
        dispatch(enableVillageMortgageFilter());

        return true;
      } else {
        dispatch(disableVillageMortgageFilter());

        /** При отключении фильтра удаляем его из query */
        dispatch(
          changeJsonQuery({
            prevJsonQuery: jsonQuery,
            appliedModifiers: [{ action: 'setVillageMortgageAllowed', arguments: [null] }],
            nextJsonQuery: new JsonQuery(jsonQuery).setVillageMortgageAllowed(null).toJSON(),
          }),
        );

        return false;
      }
    } catch (ex) {
      context.logger.error(ex);

      return false;
    }
  };
}
