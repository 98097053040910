import { ERegionMetaActionType } from '../../actions/regionMeta';
import { TReduxActions } from '../../types/redux';
import { IRegionMetaState } from '../../types/regionMeta';

const defaultState: IRegionMetaState = {
  id: 0,
  parentId: 0,
  name: '',
  namePrepositional: null,
  districts: null,
  highways: null,
  undergrounds: null,
  boundedBy: {
    lowerCorner: { lat: 0, lng: 0 },
    upperCorner: { lat: 0, lng: 0 },
  },
  center: null,
};

export function regionMetaReducer(state: IRegionMetaState = defaultState, action: TReduxActions): IRegionMetaState {
  switch (action.type) {
    case ERegionMetaActionType.Changed:
      return action.payload;
  }

  return state;
}
