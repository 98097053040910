import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EDatesModalActionType {
  Open = 'filters/datesModal/open',
  Close = 'filters/datesModal/close',
}

interface IOpenPayload {
  isAutoOpen: boolean;
}

export type TDatesModalOpen = ITypedReduxAction<EDatesModalActionType.Open, IOpenPayload>;
export type TDatesModalClose = ITypedReduxAction<EDatesModalActionType.Close>;
export type TDatesModalActions = TDatesModalOpen | TDatesModalClose;

export const openDatesModal = actionGenerator<EDatesModalActionType.Open, IOpenPayload>(EDatesModalActionType.Open);
export const closeDatesModal = actionGenerator<EDatesModalActionType.Close>(EDatesModalActionType.Close);
