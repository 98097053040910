import { ca } from '@cian/analytics';

export function trackJsonQueryChanged(label: string, operationType: string) {
  // @ts-expect-error Это хак связи микрофронта с мкс. Событие только на фронте.
  const CianAnalytics = window.CianAnalytics;

  /* istanbul ignore next */
  if (!CianAnalytics) {
    setTimeout(() => trackJsonQueryChanged(label, operationType), 5);

    return;
  }

  /* istanbul ignore next */
  const page = CianAnalytics.service.config.page || {};

  ca('eventSite', {
    name: 'oldevent',
    action: 'popup_confirm',
    category: 'FastFilters',
    label,
    page: {
      ...page,
      extra: operationType,
    },
  });
}
