import { DEFAULT_DISTRICTS } from './constants';
import { lruCache } from '../../../packages/LRUCache';
import { ResponseError } from '../../errors/ResponseError';
import {
  TRegionalDistrictsGetByLocationIdResponse,
  fetchRegionalDistrictsGetByLocationId,
} from '../../repositories/geo/v1/regional-districts-get-by-location-id';
import { IApplicationContext } from '../../types/applicationContext';

interface IFetchRegionalDistrictsParams {
  locationId: number;
}

const fetchRegionalDistricts = async (
  { httpApi, logger }: IApplicationContext,
  parameters: IFetchRegionalDistrictsParams,
) => {
  try {
    const regionalDistrictsData: TRegionalDistrictsGetByLocationIdResponse =
      await fetchRegionalDistrictsGetByLocationId({
        httpApi,
        parameters,
      });

    if (regionalDistrictsData.statusCode !== 200) {
      throw new ResponseError({
        domain: 'fetchRegionalDistricts',
        message: regionalDistrictsData.response.message,
        details: {
          error: regionalDistrictsData.response.errors,
        },
      });
    }

    return regionalDistrictsData.response;
  } catch (error) {
    logger.warning(error, { degradation: 'Областные районы' });

    return DEFAULT_DISTRICTS;
  }
};

const fetchRegionalDistrictsWithCache = lruCache(fetchRegionalDistricts, { key: 'RegionalDistricts' });

export const fetchRegionalDistrictsCached = (
  context: IApplicationContext,
  parameters: IFetchRegionalDistrictsParams,
) => {
  return fetchRegionalDistrictsWithCache(`RegionalDistricts.${parameters.locationId}`, context, context, parameters);
};
