import * as config from '@cian/config/browser';
import * as globalBehaviors from '@cian/global-behaviors/browser';
import * as httpApi from '@cian/http-api/browser';
import * as logger from '@cian/logger/browser';
import * as telemetry from '@cian/telemetry/browser';
import * as web from '@cian/web/browser';

export function setupDependencies() {
  telemetry.setup();
  httpApi.setup({});
}

export function initDependencies() {
  config.init();
  logger.init();
  telemetry.init();
  httpApi.init();
  web.init();
  globalBehaviors.init();
}
