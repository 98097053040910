import { FDealType, FOfferType, isAvailable, isAvailableStrict } from '../../../../../../JsonQuery';
import { IFilterList } from '../../types';

export const FILTERS_SUBURBAN: IFilterList[] = [
  {
    availability: isAvailableStrict(FDealType.Sale, FOfferType.House),
    list: ['price'],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.House | FOfferType.HousePart | FOfferType.Townhouse),
    list: ['price'],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.House | FOfferType.HousePart | FOfferType.Townhouse),
    list: ['price', 'dealType'],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.House),
    list: ['price', 'dealType'],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Land),
    list: ['price'],
  },
];
