import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { QuickFilterButton } from '../../../components/QuickFilters/QuickFilterButton';
import { EQuickFilterType } from '../../../types/quickFilter';
import { useContext } from '../../../utils/useContext';

export const DealTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const isByHomeowner = getTermValue('is_by_homeowner')(jsonQuery);

  const handleClick = React.useCallback(() => {
    onChange({ action: 'setIsByHomeowner', arguments: [null] });
  }, [onChange]);

  if (!isByHomeowner) {
    return null;
  }

  return <QuickFilterButton onClick={handleClick} text="От собственника" type={EQuickFilterType.Single} />;
};
