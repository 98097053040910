import { EPriceType } from '../../../../JsonQuery';

interface IPriceType {
  label: string;
  value: EPriceType;
}

export const PRICE_TYPES: IPriceType[] = [
  { label: 'за всё', value: EPriceType.Total },
  { label: 'за м\u00B2', value: EPriceType.SM },
];

export function getPriceType(isSquareMeterPrice?: boolean | null) {
  return isSquareMeterPrice ? EPriceType.SM : EPriceType.Total;
}

export function getIsSquareMeterPrice(priceType: EPriceType): boolean | null {
  return priceType === EPriceType.SM ? true : null;
}
