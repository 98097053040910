import * as R from 'ramda';

import { isCommercial, isSuburban } from './category';
import { DEFAULT_LONGTERM_RENT_OPTION, DISJOINT_AVAILABLE_OFFER_TYPES } from './constants';
import { setTerm, setTerms, setRootType, resetTerms, getTermsValue } from './helpers';
import { setDealType } from './setDealType';
import { setRoomTypes } from './setRoomTypes';
import { FDealType, FOfferType, TJsonQuery } from './types';
import { dealTypeFromJsonQuery, isAvailableOfferTypeCombination, offerTypeFromJsonQuery } from './utils';
import {
  ERoomType,
  EBuildingStatus,
  EObjectType,
  EOfficeType,
  ECategoryType,
  IJsonQueryType,
} from '../api-models/common/json_query';

export function setOfferType(jsonQuery: TJsonQuery): (nextOfferType: FOfferType) => TJsonQuery {
  return nextOfferType => {
    if (!isAvailableOfferTypeCombination(nextOfferType)) {
      return jsonQuery;
    }

    const nextOfferTypes = getNextOfferTypes(nextOfferType);

    let nextJsonQuery = R.clone(jsonQuery);

    nextJsonQuery = resetOfferTypeRelatedTerms(nextJsonQuery)(nextOfferType);

    return nextOfferTypes.reduce((jsonQuery, offerType) => selectOfferType(jsonQuery)(offerType), nextJsonQuery);
  };
}

function getNextOfferTypes(nextOfferType: FOfferType): FOfferType[] {
  let nextOfferTypes = DISJOINT_AVAILABLE_OFFER_TYPES.filter(o => (o & nextOfferType) !== 0);

  if ([FOfferType.FlatOld, FOfferType.FlatNew].every(o => nextOfferTypes.includes(o))) {
    nextOfferTypes = nextOfferTypes.filter(o => (o & FOfferType.Flat) === 0);
    nextOfferTypes.push(FOfferType.Flat);
  }

  return nextOfferTypes;
}

function resetOfferTypeRelatedTerms(jsonQuery: TJsonQuery): (nextOfferType: FOfferType) => TJsonQuery {
  return nextOfferType => {
    let nextJsonQuery = R.clone(jsonQuery);

    const dealType = dealTypeFromJsonQuery(jsonQuery);
    const offerType = offerTypeFromJsonQuery(jsonQuery);

    nextJsonQuery = resetTerms([
      'building_status',
      'category',
      'object_type',
      'office_type',
      'flat_share',
      'bath',
      'shower',
    ])(nextJsonQuery);

    if (nextOfferType !== FOfferType.FlatNew) {
      nextJsonQuery = resetTerms(['with_newobject', 'from_developer'])(nextJsonQuery);
    }

    const room = getTermsValue('room')(nextJsonQuery);
    if (room) {
      nextJsonQuery = setRoomTypes(nextJsonQuery)(
        room.filter(r => ![ERoomType.Room, ERoomType.FlatShared, ERoomType.Bed].includes(r)),
      );
    }

    if (isCommercial(offerType) && !isCommercial(nextOfferType)) {
      if (dealType & FDealType.Rent) {
        nextJsonQuery = setTerm('for_day')(nextJsonQuery)(DEFAULT_LONGTERM_RENT_OPTION);
      }
    }

    if (isSuburban(offerType) && !isSuburban(nextOfferType)) {
      nextJsonQuery = resetTerms(['kp_id'])(nextJsonQuery);
    }

    return nextJsonQuery;
  };
}

function selectOfferType(jsonQuery: TJsonQuery): (nextOfferType: FOfferType) => TJsonQuery {
  return nextOfferType => {
    const dealType = dealTypeFromJsonQuery(jsonQuery);

    let nextJsonQuery = R.clone(jsonQuery);

    switch (nextOfferType) {
      case FOfferType.Flat:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('flat');
        break;
      case FOfferType.FlatOld:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('flat');

        if ((dealType & FDealType.Rent) === 0) {
          nextJsonQuery = setTerm('building_status')(nextJsonQuery)(EBuildingStatus.Old);
        }
        break;
      case FOfferType.FlatNew:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('flat');
        nextJsonQuery = setTerm('building_status')(nextJsonQuery)(EBuildingStatus.New);
        nextJsonQuery = setTerm('with_newobject')(nextJsonQuery)(true);
        break;
      case FOfferType.FlatShared:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('flat');
        nextJsonQuery = appendRoomType(nextJsonQuery)(ERoomType.FlatShared);
        break;
      case FOfferType.Room:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('flat');
        nextJsonQuery = appendRoomType(nextJsonQuery)(ERoomType.Room);
        break;
      case FOfferType.Bed:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('flat');
        nextJsonQuery = appendRoomType(nextJsonQuery)(ERoomType.Bed);
        break;
      case FOfferType.House:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('suburban');
        nextJsonQuery = appendSuburbanObjectType(nextJsonQuery)(EObjectType.House);
        break;
      case FOfferType.Townhouse:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('suburban');
        nextJsonQuery = appendSuburbanObjectType(nextJsonQuery)(EObjectType.Townhouse);
        break;
      case FOfferType.HousePart:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('suburban');
        nextJsonQuery = appendSuburbanObjectType(nextJsonQuery)(EObjectType.Housepart);
        break;
      case FOfferType.Land:
        nextJsonQuery = setDealType(nextJsonQuery)(FDealType.Sale);
        nextJsonQuery = setRootOfferType(nextJsonQuery)('suburban');
        nextJsonQuery = appendSuburbanObjectType(nextJsonQuery)(EObjectType.Land);
        break;
      case FOfferType.Garage:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = setTerms('office_type')(nextJsonQuery)([EOfficeType.Garage]);
        break;
      case FOfferType.Office:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = appendOfficeType(nextJsonQuery)(EOfficeType.Office);
        break;
      case FOfferType.TradeArea:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = appendOfficeType(nextJsonQuery)(EOfficeType.TradeArea);
        break;
      case FOfferType.Warehouse:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = appendOfficeType(nextJsonQuery)(EOfficeType.Warehouse);
        break;
      case FOfferType.FreeAppointmentObject:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = appendOfficeType(nextJsonQuery)(EOfficeType.FreeAppointmentObject);
        break;
      case FOfferType.PublicCatering:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = appendOfficeType(nextJsonQuery)(EOfficeType.PublicCatering);
        break;
      case FOfferType.Manufacture:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = appendOfficeType(nextJsonQuery)(EOfficeType.Manufacture);
        break;
      case FOfferType.AutoService:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = appendOfficeType(nextJsonQuery)(EOfficeType.AutoService);
        break;
      case FOfferType.Business:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = appendOfficeType(nextJsonQuery)(EOfficeType.Business);
        break;
      case FOfferType.Building:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = appendOfficeType(nextJsonQuery)(EOfficeType.Building);
        break;
      case FOfferType.DomesticServices:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = appendOfficeType(nextJsonQuery)(EOfficeType.DomesticServices);
        break;
      case FOfferType.Coworking:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = appendOfficeType(nextJsonQuery)(EOfficeType.Coworking);
        break;
      case FOfferType.CommercialLand:
        nextJsonQuery = setRootOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = setTerms('office_type')(nextJsonQuery)(null);
        if (dealType & FDealType.Rent) {
          nextJsonQuery = setTerms('category')(nextJsonQuery)([ECategoryType.CommercialLandRent]);
        } else if (dealType & FDealType.Sale) {
          nextJsonQuery = setTerms('category')(nextJsonQuery)([ECategoryType.CommercialLandSale]);
        }
        break;
    }

    return nextJsonQuery;
  };
}

function appendSuburbanObjectType(jsonQuery: TJsonQuery) {
  return (objectType: EObjectType): TJsonQuery => {
    const objectTypes: EObjectType[] = getTermsValue('object_type')(jsonQuery) || [];

    return setTerms('object_type')(jsonQuery)(objectTypes.concat(objectType));
  };
}

function appendRoomType(jsonQuery: TJsonQuery) {
  return (roomType: ERoomType): TJsonQuery => {
    const roomTypes: ERoomType[] = getTermsValue('room')(jsonQuery) || [];

    return setTerms('room')(jsonQuery)(roomTypes.concat(roomType));
  };
}

function appendOfficeType(jsonQuery: TJsonQuery) {
  return (officeType: EOfficeType): TJsonQuery => {
    const officeTypes: EOfficeType[] = getTermsValue('office_type')(jsonQuery) || [];

    return setTerms('office_type')(jsonQuery)(officeTypes.concat(officeType));
  };
}

function setRootOfferType(jsonQuery: TJsonQuery) {
  return (offerType: 'flat' | 'commercial' | 'suburban' | 'newobject'): TJsonQuery => {
    const currentType = jsonQuery._type;

    const nextType: IJsonQueryType = currentType.replace(
      /flat|commercial|suburban|newobject/,
      offerType,
    ) as IJsonQueryType;

    return setRootType(nextType)(jsonQuery);
  };
}
