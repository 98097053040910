import { EGetUserStatus } from './types';
import { ResponseError } from '../../errors';
import { prepareUser } from '../../mappers/prepareUser';
import {
  fetchGetUserDataByRequest,
  TGetUserDataByRequestResponse,
} from '../../repositories/monolith-python/v1/get-user-data-by-request';
import { TThunkAction } from '../../types/redux';
import { TUser } from '../../types/user';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const setStatusLoading = actionGenerator<EGetUserStatus.Loading>(EGetUserStatus.Loading);
const setStatusSucceed = actionGenerator<EGetUserStatus.Succeed, TUser>(EGetUserStatus.Succeed);
const setStatusFailed = actionGenerator<EGetUserStatus.Failed>(EGetUserStatus.Failed);

export function getUser(): TThunkAction<Promise<TUser | undefined>> {
  return async (dispatch, getState, context) => {
    dispatch(setStatusLoading());

    const {
      logger,
      httpApi,
      custom: { subdomain },
    } = context;

    try {
      const getUserDataByRequestPromise: Promise<TGetUserDataByRequestResponse> = fetchGetUserDataByRequest({
        httpApi,
        parameters: {},
        config: {
          subdomain,
        },
      });

      const userData = await getUserDataByRequestPromise;

      if (userData.statusCode !== 200) {
        throw new ResponseError({
          domain: 'fetchUser',
          message: userData.response.message,
          details: {
            error: userData.response.errors,
          },
        });
      }

      const user: TUser = prepareUser(userData.response);

      dispatch(setStatusSucceed(user));

      return user;
    } catch (error) {
      logger.error(error);

      dispatch(setStatusFailed());

      return undefined;
    }
  };
}
