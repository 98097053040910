import { lruCache } from '../../../packages/LRUCache';
import { ResponseError } from '../../errors';
import { prepareRegionMeta } from '../../mappers/prepareRegionMeta';
import { fetchGetRegionMeta, TGetRegionMetaResponse } from '../../repositories/geo-temp-layer/v1/get-region-meta';
import { IApplicationContext } from '../../types/applicationContext';
import { IRegionMetaState } from '../../types/regionMeta';

interface IFetchRegionMetaParameters {
  locationId: number;
}

async function fetchRegionMeta(
  context: IApplicationContext,
  { locationId }: IFetchRegionMetaParameters,
): Promise<IRegionMetaState | null> {
  const { httpApi } = context;

  const regionMetaData: TGetRegionMetaResponse = await fetchGetRegionMeta({
    httpApi,
    parameters: {
      locationId,
    },
  });

  if (regionMetaData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchRegionMeta',
      message: regionMetaData.response.message,
      details: {
        error: regionMetaData.response.errors,
      },
    });
  }

  return prepareRegionMeta(regionMetaData.response);
}

const fetchRegionMetaWithCache = lruCache(fetchRegionMeta, { key: 'regionMeta' });

export const fetchRegionMetaCached = (context: IApplicationContext, parameters: IFetchRegionMetaParameters) => {
  return fetchRegionMetaWithCache(`regionMeta.${parameters.locationId}`, context, context, parameters);
};
