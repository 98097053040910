import { IConfig } from '@cian/config/shared';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger';
import * as React from 'react';

import { NonEmptyArray, modifyJsonQuery } from '../../../../Fields';
import { TJsonQuery } from '../../../../JsonQuery';
import { TModifier } from '../../types/modifier';
import { IContext, context as Context } from '../../utils/context';

export interface IOnChangeParameters {
  appliedModifiers: NonEmptyArray<TModifier>;
  nextJsonQuery: TJsonQuery;
}

interface IProviderProps {
  logger: ILogger;
  config: IConfig;
  httpApi: IHttpApi;
  jsonQuery: TJsonQuery;
  onChange(parameters: IOnChangeParameters): void;
  onFiltersButtonClick(): void;
}

export const Provider: React.FC<React.PropsWithChildren<IProviderProps>> = ({
  children,
  logger,
  config,
  httpApi,
  jsonQuery,
  onChange,
  onFiltersButtonClick,
}) => {
  const handleChange = React.useCallback(
    (...modifiers: NonEmptyArray<TModifier>) => {
      onChange({
        appliedModifiers: modifiers,
        nextJsonQuery: modifyJsonQuery(jsonQuery, modifiers),
      });
    },
    [jsonQuery, onChange],
  );

  const context = React.useMemo<IContext>(
    () => ({
      logger,
      config,
      httpApi,
      jsonQuery,
      onChange: handleChange,
      onFiltersButtonClick,
    }),
    [logger, config, httpApi, jsonQuery, handleChange, onFiltersButtonClick],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};
