import * as React from 'react';

import { JsonQuery } from '../../../../../JsonQuery';
import { QuickFilterButton } from '../../../components/QuickFilters/QuickFilterButton';
import { EQuickFilterType } from '../../../types/quickFilter';
import { useContext } from '../../../utils/useContext';

export const ApartmentsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const apartments = new JsonQuery(jsonQuery).getApartments();

  const handleClick = React.useCallback(() => {
    onChange({ action: 'setApartments', arguments: [null] });
  }, [onChange]);

  if (apartments === null) {
    return null;
  }

  return (
    <QuickFilterButton
      onClick={handleClick}
      text={apartments ? 'Только апартаменты' : 'Без апартаментов'}
      type={EQuickFilterType.Single}
    />
  );
};
