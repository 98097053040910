import { TDatesModalActions } from './datesModal';
import { NonEmptyArray, TModifier } from '../../../packages/Fields';
import { TJsonQuery } from '../../../packages/JsonQuery';
import { TPromoSearchTypes } from '../../types/promoSearchTypes';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { TTag } from '../../types/tags';

export enum EGeoTagsActionType {
  Loading = 'filters/geoTags/loading',
  Succeed = 'filters/geoTags/succeed',
  Failed = 'filters/geoTags/failed',
}

export enum EPromoSearchTypesActionType {
  Loading = 'filters/promoSearchTypes/loading',
  Succeed = 'filters/promoSearchTypes/succeed',
  Failed = 'filters/promoSearchTypes/failed',
}

export enum EFiltersActionType {
  JsonQueryChanged = 'filters/jsonQuery/changed',
}

export enum EVillageMortgageActionType {
  Enable = 'filters/villageMortgageFilter/enable',
  Disable = 'filters/villageMortgageFilter/disable',
}

export interface IJsonQueryChanged {
  appliedModifiers: NonEmptyArray<TModifier>;
  prevJsonQuery: TJsonQuery;
  nextJsonQuery: TJsonQuery;
}

export type TJsonQueryChangedAction = ITypedReduxAction<EFiltersActionType.JsonQueryChanged, IJsonQueryChanged>;
export type TFetchTagsLoading = ITypedReduxAction<EGeoTagsActionType.Loading>;
export type TFetchTagsSucceed = ITypedReduxAction<EGeoTagsActionType.Succeed, TTag[] | null>;
export type TFetchTagsFailed = ITypedReduxAction<EGeoTagsActionType.Failed>;
export type TVillageMortgageEnable = ITypedReduxAction<EVillageMortgageActionType.Enable>;
export type TVillageMortgageDisable = ITypedReduxAction<EVillageMortgageActionType.Disable>;
export type TPromoSearchTypesLoading = ITypedReduxAction<EPromoSearchTypesActionType.Loading>;
export type TPromoSearchTypesSucceed = ITypedReduxAction<EPromoSearchTypesActionType.Succeed, TPromoSearchTypes | null>;
export type TPromoSearchTypesFailed = ITypedReduxAction<EPromoSearchTypesActionType.Failed>;

export type TFiltersActions =
  | TFetchTagsLoading
  | TFetchTagsSucceed
  | TFetchTagsFailed
  | TJsonQueryChangedAction
  | TVillageMortgageEnable
  | TVillageMortgageDisable
  | TDatesModalActions
  | TPromoSearchTypesLoading
  | TPromoSearchTypesSucceed
  | TPromoSearchTypesFailed;
