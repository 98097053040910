import * as React from 'react';

import { FOfferType } from '../../../../../JsonQuery';
import { QuickFilterButton } from '../../../components/QuickFilters/QuickFilterButton';
import { FlatTypeModal, getFlatTypeLabel } from '../../../components/filters/FlatTypeModal';
import { EQuickFilterType } from '../../../types/quickFilter';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const FlatTypeContainer: React.FC = () => {
  const { onChange } = useContext();
  const offerType = useOfferType();

  const [modalOpened, setModalOpened] = React.useState(false);

  const label = React.useMemo(() => getFlatTypeLabel(offerType, true), [offerType]);

  const handleChange = React.useCallback(
    (offerType: FOfferType) => {
      setModalOpened(false);
      onChange({ action: 'setOfferType', arguments: [offerType] });
    },
    [onChange],
  );

  const handleClick = React.useCallback(() => setModalOpened(true), []);
  const handleClose = React.useCallback(() => setModalOpened(false), []);

  if (!offerType) {
    return null;
  }

  return (
    <>
      <QuickFilterButton onClick={handleClick} text={label} type={EQuickFilterType.Advanced} />
      <FlatTypeModal onChange={handleChange} value={offerType} open={modalOpened} onClose={handleClose} />
    </>
  );
};
