import { FDealType, FOfferType, isAvailable } from '../../../../../../JsonQuery';
import { IFilterList } from '../../types';

export const FILTERS_URBAN: IFilterList[] = [
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Flat),
    list: [
      'flatType',
      'roomType',
      'price',
      'dealType', // Докинуть "От застройщика"
      'apartments',
      'demolition',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Flat),
    list: ['roomType', 'price', 'dealType', 'demolition', 'apartments'],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Flat),
    list: ['roomType', 'price', 'dealType'],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Room | FOfferType.FlatShared),
    list: ['flatRooms', 'price', 'dealType', 'demolition', 'apartments'],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Room | FOfferType.Bed),
    list: ['flatRooms', 'price', 'dealType', 'demolition', 'apartments'],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Room | FOfferType.Bed),
    list: ['price', 'dealType', 'flatRooms'],
  },
];
