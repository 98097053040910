import { ERegionMetaActionType } from '../../actions/regionMeta';
import { IPageState } from '../../types/page';
import { TReduxActions } from '../../types/redux';
import { getTitle } from '../../utils/page';

const defaultState: IPageState = {
  title: '',
};

export function pageReducer(state: IPageState = defaultState, action: TReduxActions): IPageState {
  switch (action.type) {
    case ERegionMetaActionType.Changed:
      return {
        ...state,
        title: getTitle(action.payload.namePrepositional),
      };
  }

  return state;
}
