import { Switch } from '@cian/ui-kit/checkbox';
import * as React from 'react';

import { Cell, ICellProps } from '../Cell';

interface ICellSwitchProps extends Omit<ICellProps, 'accessory'> {
  checked?: boolean;
}

// FIXME: "input" из Switch не может быть внутри "a" из Cell. Надо разрулить эту ситуацию.
export const CellSwitch: React.FC<ICellSwitchProps> = ({ checked, onClick, ...sharedProps }) => {
  const handleSwitchClick = React.useCallback(
    (event: React.MouseEvent<HTMLLabelElement>) => {
      event.preventDefault();
      event.stopPropagation();

      onClick();
    },
    [onClick],
  );

  const handleClick = React.useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <Cell {...sharedProps} accessory={<Switch checked={checked} onClick={handleSwitchClick} />} onClick={handleClick} />
  );
};
