import { EGeoTagsActionType } from '../../actions/filters';
import { EGeoTagsStatus, IGeoTagsState } from '../../types/filters';
import { TReduxActions } from '../../types/redux';

const defaultState: IGeoTagsState = {
  status: EGeoTagsStatus.Initial,
  items: null,
};

export function geoTagsReducer(state: IGeoTagsState = defaultState, action: TReduxActions): IGeoTagsState {
  switch (action.type) {
    case EGeoTagsActionType.Loading:
      return {
        ...state,
        status: EGeoTagsStatus.Loading,
      };
    case EGeoTagsActionType.Succeed:
      return {
        ...state,
        status: EGeoTagsStatus.Succeed,
        items: action.payload,
      };
    case EGeoTagsActionType.Failed:
      return {
        ...state,
        status: EGeoTagsStatus.Failed,
      };
  }

  return state;
}
