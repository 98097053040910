import { EAuthenticationActionTypes, TModalClose } from './types';
import { getEvents } from '../../services/events';

export const modalClose = (isAuthenticated?: boolean): TModalClose => {
  if (!isAuthenticated) {
    getEvents().authModalClosedNoAuth();
  }

  return {
    type: EAuthenticationActionTypes.ModalClose,
    payload: undefined,
  };
};
