import { UIHeading4 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './Header.css';

export interface IHeaderProps {
  title?: string;
  left?: React.ReactChild;
  right?: React.ReactChild;
}

export const Header: React.FC<IHeaderProps> = ({ title, left, right }) => {
  return (
    <div className={styles['container']}>
      {left && <div className={styles['left']}>{left}</div>}
      {title && (
        <div className={styles['center']}>
          <UIHeading4>{title}</UIHeading4>
        </div>
      )}
      <div className={styles['right']}>{right}</div>
    </div>
  );
};
