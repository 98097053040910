const PARAMS_TO_KEEP = ['zoom', 'bbox', 'engine_version', 'offer_type', 'region'];

export const getDailyrentUrl = (url: string, dailyrentCleanUrl: string) => {
  const { origin, pathname, search } = new URL(url);
  const params = new URLSearchParams(search);
  const paramsToKeep = new URLSearchParams();

  // если исходный роут – ЧПУ, ведем на дефолтную ЧПУ посутки
  if (!search) {
    return `${origin}/${dailyrentCleanUrl}`;
  }

  for (const param of PARAMS_TO_KEEP) {
    if (params.has(param)) {
      paramsToKeep.set(param, params.get(param) as string);
    }
  }

  paramsToKeep.set('type', '2');
  paramsToKeep.set('deal_type', 'rent');

  return `${origin + pathname}?${paramsToKeep.toString()}`;
};
