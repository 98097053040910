import { Button } from '@cian/ui-kit';
import { IconActionChevronDownSmall16, IconActionClose12 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './QuickFilterButton.css';
import { EQuickFilterType } from '../../types/quickFilter';

interface IQuickFilterButtonProps {
  onClick(): void;
  type: EQuickFilterType;
  text: string;
}

export const QuickFilterButton: React.FC<IQuickFilterButtonProps> = ({ type, onClick, text }) => (
  <div className={styles['container']}>
    <Button theme="fill_secondary" onClick={onClick} color="black100">
      {
        /* Это хак, но тянуть все вспомогательные стили кнопки дорого */
        (
          <span className={styles['button']}>
            {text}
            {type === EQuickFilterType.Single ? <IconActionClose12 /> : <IconActionChevronDownSmall16 />}
          </span>
        ) as unknown as string
      }
    </Button>
  </div>
);
