import { Button } from '@cian/ui-kit/button';
import * as React from 'react';

import * as styles from './StickyApplyButton.css';

interface IStickyApplyButtonProps {
  onClick(): void;
}

export const StickyApplyButton: React.FC<IStickyApplyButtonProps> = ({ onClick }) => {
  return (
    <div className={styles['container']}>
      <Button theme="fill_primary" fullWidth onClick={onClick} size="L">
        Применить
      </Button>
    </div>
  );
};
