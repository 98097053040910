import { ECommercialMetaActionType } from '../../actions/commercialMeta';
import { ECommercialMetaStatus, ICommercialMetaState } from '../../types/commercialMeta';
import { TReduxActions } from '../../types/redux';

const defaultState: ICommercialMetaState = {
  status: ECommercialMetaStatus.Initial,
  meta: {
    count: 0,
    queryString: '',
    seoPath: null,
    subdomain: 'www',
    placeholders: {},
  },
};

export function commercialMetaReducer(
  state: ICommercialMetaState = defaultState,
  action: TReduxActions,
): ICommercialMetaState {
  switch (action.type) {
    case ECommercialMetaActionType.Loading:
      return {
        ...state,
        status: ECommercialMetaStatus.Loading,
      };
    case ECommercialMetaActionType.Succeed:
      return {
        ...state,
        status: ECommercialMetaStatus.Succeed,
        meta: action.payload,
      };
    case ECommercialMetaActionType.Failed:
      return {
        ...state,
        status: ECommercialMetaStatus.Failed,
      };
  }

  return state;
}
