import { ERegionalDistrictsActionType } from '../../actions/regionalDistricts/types';
import { IApplicationState, TReduxActions } from '../../types/redux';

export const defaultState: IApplicationState['regionalDistricts'] = {
  popularDistricts: [],
  districts: [],
};

export function regionalDistrictsReducer(
  state: IApplicationState['regionalDistricts'] = defaultState,
  action: TReduxActions,
): IApplicationState['regionalDistricts'] {
  switch (action.type) {
    case ERegionalDistrictsActionType.Changed:
      return action.payload;
  }

  return state;
}
