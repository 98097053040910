import { UIHeading2 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './OfferTypeSelect.css';
import { IOfferTypeSelectOptionsGroup, IOfferTypeSelectOption } from './types';
import { FOfferType, isAvailableOfferTypeCombination } from '../../../../JsonQuery';
import { CellCheckbox, CellGroup } from '../CellGroup';

export interface IOfferTypeSelectProps {
  options: IOfferTypeSelectOptionsGroup[];
  selected: FOfferType[];

  onChange(value: FOfferType[]): void;
}

export const OfferTypeSelect: React.FC<IOfferTypeSelectProps> = ({ options, selected, onChange }) => {
  const handleSelect = React.useCallback(
    (option: IOfferTypeSelectOption) => {
      if (selected.includes(option.value)) {
        if (selected.length > 1) {
          onChange(selected.filter(o => o !== option.value));
        }
      } else {
        if (
          isAvailableOfferTypeCombination(
            [...selected, option.value].reduce(
              (previousValue, currentValue) => previousValue | currentValue,
              FOfferType.Unexpected,
            ),
          )
        ) {
          onChange([...selected, option.value]);
        } else {
          onChange([option.value]);
        }
      }
    },
    [onChange, selected],
  );

  return (
    <div className={styles['options']}>
      {options.map((optionsGroup, optionsGroupIndex) => (
        <React.Fragment key={`optionsGroup_${optionsGroupIndex}`}>
          {optionsGroup.label && (
            <div className={styles['header']}>
              <UIHeading2>{optionsGroup.label}</UIHeading2>
            </div>
          )}
          <CellGroup>
            {optionsGroup.options.map((option, optionIndex) => (
              <CellCheckbox
                checked={selected.includes(option.value)}
                key={`option_${optionsGroupIndex}_${optionIndex}`}
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </CellCheckbox>
            ))}
          </CellGroup>
        </React.Fragment>
      ))}
    </div>
  );
};
