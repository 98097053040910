import * as React from 'react';

import { PriceField } from '../../../../../Fields/shared/components/PriceField';
import { FDealType, FOfferType, NonEmptyArray } from '../../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { TModifier } from '../../../types/modifier';
import { ModalWindow } from '../../ModalWindow';

export interface IPriceModalProps {
  dealType: FDealType;
  offerType: FOfferType;
  price: IJsonQueryRangeValue | null;
  isSquareMeterPrice: boolean | null;
  onChange(...modifiers: NonEmptyArray<TModifier>): void;
  open: boolean;
  onClose(): void;
}

export const PriceModal: React.FC<IPriceModalProps> = ({
  dealType,
  offerType,
  price,
  isSquareMeterPrice: initialIsSquareMeterPrice,
  onChange,
  open,
  onClose,
}) => {
  const [priceMin, setPriceMin] = React.useState<number | null>(null);
  const [priceMax, setPriceMax] = React.useState<number | null>(null);
  const [isSquareMeterPrice, setIsSquareMeterPrice] = React.useState<boolean | null>(null);

  React.useEffect(() => setPriceMin(price?.gte || null), [price]);
  React.useEffect(() => setPriceMax(price?.lte || null), [price]);
  React.useEffect(() => setIsSquareMeterPrice(initialIsSquareMeterPrice), [initialIsSquareMeterPrice]);

  const handlePriceMinChange = React.useCallback((gte: number | null) => setPriceMin(gte), []);
  const handlePriceMaxChange = React.useCallback((lte: number | null) => setPriceMax(lte), []);
  const handlePriceTypeChange = React.useCallback(
    (isSquareMeterPrice: boolean | null) => setIsSquareMeterPrice(isSquareMeterPrice),
    [],
  );

  const handleChange = React.useCallback(
    () =>
      onChange(
        { action: 'setPriceMin', arguments: [priceMin] },
        { action: 'setPriceMax', arguments: [priceMax] },
        { action: 'setPriceType', arguments: [isSquareMeterPrice] },
      ),
    [priceMin, priceMax, onChange, isSquareMeterPrice],
  );

  const onReset = React.useCallback(() => {
    setPriceMin(null);
    setPriceMax(null);
    setIsSquareMeterPrice(null);
    onChange(
      { action: 'setPriceMin', arguments: [null] },
      { action: 'setPriceMax', arguments: [null] },
      { action: 'setPriceType', arguments: [null] },
    );
  }, [onChange]);

  return (
    <ModalWindow title="Цена" onChange={handleChange} open={open} onClose={onClose} onReset={onReset}>
      <PriceField
        dealType={dealType}
        offerType={offerType}
        price={{ gte: priceMin || undefined, lte: priceMax || undefined }}
        isSquareMeterPrice={isSquareMeterPrice}
        onPriceMinChange={handlePriceMinChange}
        onPriceMaxChange={handlePriceMaxChange}
        onPriceTypeChange={handlePriceTypeChange}
      />
    </ModalWindow>
  );
};
