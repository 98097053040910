import { ConfigurationError } from '@cian/peperrors/shared';
import * as React from 'react';

import { getAvailableFilters } from './helpers';
import { QuickFilters } from '../../components/QuickFilters';
import { FILTERS } from '../../constants/filters';
import { useContext } from '../../utils/useContext';

export const QuickFiltersContainer: React.FC = () => {
  const context = useContext();
  if (!context) {
    throw new ConfigurationError({
      domain: 'Filters',
      message: "Filters used outside of it's context",
    });
  }

  const { jsonQuery, onFiltersButtonClick } = context;
  const availableFilters = React.useMemo(
    () =>
      getAvailableFilters({
        jsonQuery,
      })
        .map(filterKey => {
          const { availability, component, priority } = FILTERS[filterKey];

          if (availability && !availability(jsonQuery)) {
            return {};
          }

          return { priority, component, filterKey };
        })
        .filter(({ component }) => !!component)
        .sort((a, b) => {
          if (!a?.priority) {
            return 1;
          }

          if (!b?.priority) {
            return -1;
          }

          return 0;
        })
        // @ts-expect-error Все невалидные элементы отфильтруются
        .map(({ component: Component, filterKey }) => <Component key={`quick_filter_${filterKey}`} />),
    [jsonQuery],
  );

  return <QuickFilters onFiltersButtonClick={onFiltersButtonClick}>{availableFilters}</QuickFilters>;
};
