/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetSearchTypesModel,
  IGetSearchTypesRequest,
  IMappers,
  IGetSearchTypesResponse,
  IGetSearchTypesResponseError,
  TGetSearchTypesResponse,
} from './types';

const defaultConfig: TGetSearchTypesModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'newbuilding-promos',
  pathApi: '/v1/get-search-types/',
  hostType: 'api',
} as TGetSearchTypesModel;

function createGetSearchTypesModel(parameters: IGetSearchTypesRequest): TGetSearchTypesModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetSearchTypesOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetSearchTypesRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetSearchTypes<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetSearchTypesOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetSearchTypesResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetSearchTypesModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetSearchTypesResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetSearchTypesResponseError);
    }
  }

  return { response, statusCode } as TGetSearchTypesResponse;
}

export { defaultConfig, createGetSearchTypesModel, fetchGetSearchTypes };
