import { TFiltersActions } from '../../actions/filters';
import { EDatesModalActionType } from '../../actions/filters/datesModal';
import { IDatesModalState } from '../../types/filters/datesModal';

export const defaultState: IDatesModalState = {
  isOpen: false,
  isAutoOpenTriggered: false,
  wasOpenedAtLeastOnce: false,
};

export function datesModalReducer(state = defaultState, action: TFiltersActions): IDatesModalState {
  switch (action.type) {
    case EDatesModalActionType.Open:
      return {
        ...state,
        isOpen: true,
        wasOpenedAtLeastOnce: true,
        isAutoOpenTriggered: action.payload.isAutoOpen,
      };
    case EDatesModalActionType.Close:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}
