import * as React from 'react';

import { getFlatRoomTypeLabel } from './getFlatRoomTypeLabel';
import { getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { ERoomsTotal } from '../../../../../api-models/common/json_query';
import { QuickFilterButton } from '../../../components/QuickFilters/QuickFilterButton';
import { FlatRoomsModal } from '../../../components/filters/FlatRoomsModal';
import { EQuickFilterType } from '../../../types/quickFilter';
import { useContext } from '../../../utils/useContext';

export const FlatRoomsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const rooms = getTermsValue('rooms_count')(jsonQuery);
  const [modalOpened, setModalOpened] = React.useState(false);

  const handleChange = React.useCallback(
    (value: NonEmptyArray<ERoomsTotal> | null) => {
      setModalOpened(false);
      onChange({ action: 'setRoomsCount', arguments: [value] });
    },
    [onChange],
  );

  const label = React.useMemo(() => (rooms ? getFlatRoomTypeLabel(rooms) : 'Комнатность'), [rooms]);

  const handleClick = React.useCallback(() => setModalOpened(true), []);
  const handleClose = React.useCallback(() => setModalOpened(false), []);

  return (
    <>
      <QuickFilterButton onClick={handleClick} text={label} type={EQuickFilterType.Advanced} />
      <FlatRoomsModal value={rooms} onChange={handleChange} onClose={handleClose} open={modalOpened} />
    </>
  );
};
