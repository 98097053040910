import { IGetRegionMetaResponse } from '../../repositories/geo-temp-layer/v1/get-region-meta';
import { IDistrict, IHighwaysGroup, IRegionMetaState } from '../../types/regionMeta';

export function prepareRegionMeta(response: IGetRegionMetaResponse): IRegionMetaState | null {
  const { location } = response;
  if (!location) {
    return null;
  }

  return {
    id: location.id as number,
    parentId: location.parentId || null,
    name: location.displayName || '',
    namePrepositional:
      location.prepositionalPretext && location.namePrepositional
        ? `${location.prepositionalPretext} ${location.namePrepositional}`
        : null,
    districts: location.hasDistricts ? prepareDistricts(response.districts) : null,
    highways: location.hasHighway ? prepareHighways(response.highways) : null,
    undergrounds: location.hasMetro ? prepareUndergrounds(response.underground) : null,
    boundedBy: location.boundedBy ?? { lowerCorner: { lat: 0, lng: 0 }, upperCorner: { lat: 0, lng: 0 } },
    center: location.lat && location.lng ? { lat: location.lat, lng: location.lng } : null,
  };
}

function prepareHighways(responseHighways: IGetRegionMetaResponse['highways']): IRegionMetaState['highways'] {
  return responseHighways.map<IHighwaysGroup>(d => ({
    name: d.name,
    highways: d.highways.map(c => ({ id: c.id, name: c.fullName })),
  }));
}

function prepareDistricts(responseDistricts: IGetRegionMetaResponse['districts']): IRegionMetaState['districts'] {
  return responseDistricts.map<IDistrict>(d => ({
    id: d.id,
    name: d.name,
    childs: d.childs.length > 0 ? d.childs.map(c => ({ id: c.id, name: c.name })) : null,
  }));
}

function prepareUndergrounds(
  responseUndergrounds: IGetRegionMetaResponse['underground'],
): IRegionMetaState['undergrounds'] {
  return responseUndergrounds
    .map(u => {
      if (!u.lineId) {
        return null;
      }

      return {
        id: u.id,
        lineColor: `#${u.lineColor}`,
        lineId: u.lineId,
        lineName: u.lineName,
        name: u.name,
        releaseYear: u.releaseYear ?? null,
        underConstruction: !!u.underConstruction,
      };
    })
    .filter(Boolean) as IRegionMetaState['undergrounds'];
}
