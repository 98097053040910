import { EPromoSearchTypesActionType } from './types';
import { fetchPromoSearchTypesCached } from '../../services/fetchPromoSearchTypes';
import { TPromoSearchTypes } from '../../types/promoSearchTypes';
import { TThunkAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux/actionGenerator';

const setStatusLoading = actionGenerator<EPromoSearchTypesActionType.Loading>(EPromoSearchTypesActionType.Loading);
const setStatusSucceed = actionGenerator<EPromoSearchTypesActionType.Succeed, TPromoSearchTypes | null>(
  EPromoSearchTypesActionType.Succeed,
);
const setStatusFailed = actionGenerator<EPromoSearchTypesActionType.Failed>(EPromoSearchTypesActionType.Failed);

export function updatePromoSearchTypes(): TThunkAction<Promise<TPromoSearchTypes | null>> {
  return async (dispatch, getState, context) => {
    dispatch(setStatusLoading());

    const {
      regionMeta: { id },
    } = getState();

    try {
      const promoSearchTypes = await fetchPromoSearchTypesCached(context, {
        regionId: id,
      });

      dispatch(setStatusSucceed(promoSearchTypes));

      return promoSearchTypes;
    } catch (ex) {
      const { logger } = context;

      logger.error(ex);

      dispatch(setStatusFailed());

      return null;
    }
  };
}
