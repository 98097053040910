import { EPromoSearchTypesActionType } from '../../actions/filters';
import { EPromoSearchTypesStatus, IPromoSearchTypesState } from '../../types/filters';
import { TReduxActions } from '../../types/redux';

const defaultState: IPromoSearchTypesState = {
  status: EPromoSearchTypesStatus.Initial,
  items: null,
};

export function promoSearchTypesReducer(state: IPromoSearchTypesState = defaultState, action: TReduxActions) {
  switch (action.type) {
    case EPromoSearchTypesActionType.Loading:
      return {
        ...state,
        status: EPromoSearchTypesStatus.Loading,
      };
    case EPromoSearchTypesActionType.Succeed:
      return {
        ...state,
        status: EPromoSearchTypesStatus.Succeed,
        items: action.payload,
      };
    case EPromoSearchTypesActionType.Failed:
      return {
        ...state,
        status: EPromoSearchTypesStatus.Failed,
      };
  }

  return state;
}
