import { ITypedReduxAction } from '../../types/redux/actionType';
import { IRegionMetaState } from '../../types/regionMeta';

export enum ERegionMetaActionType {
  Changed = 'regionMeta/changed',
}

export type TChangedAction = ITypedReduxAction<ERegionMetaActionType.Changed, IRegionMetaState>;

export type TRegionMetaActions = TChangedAction;
