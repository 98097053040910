import { lruCache } from '../../../packages/LRUCache';
import { ResponseError } from '../../errors/ResponseError';
import {
  fetchGetRegionExtensionItem,
  TGetRegionExtensionItemResponse,
} from '../../repositories/countryside-region-extension/v1/get-region-extension-item';
import { IApplicationContext } from '../../types/applicationContext';

interface IFetchExtensionRegionParams {
  regionId: number;
}

export const fetchExtensionRegion = async (
  { httpApi, logger }: IApplicationContext,
  parameters: IFetchExtensionRegionParams,
) => {
  try {
    const extensionRegionData: TGetRegionExtensionItemResponse = await fetchGetRegionExtensionItem({
      httpApi,
      parameters,
    });

    if (extensionRegionData.statusCode !== 200) {
      throw new ResponseError({
        domain: 'fetchExtensionRegion',
        message: extensionRegionData.response.message,
        details: {
          error: extensionRegionData.response.errors,
        },
      });
    }

    return extensionRegionData.response.regionItem;
  } catch (error) {
    logger.warning(error, { degradation: 'Расширение региона' });

    return null;
  }
};

const fetchExtensionRegionWithCache = lruCache(fetchExtensionRegion, { key: 'extensionRegion' });

export const fetchExtensionRegionCached = (context: IApplicationContext, parameters: IFetchExtensionRegionParams) => {
  return fetchExtensionRegionWithCache(`extensionRegion.${parameters.regionId}`, context, context, parameters);
};
