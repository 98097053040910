import { IconActionChevronRight16, ISVGIconProps } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './CellEntry.css';
import { Cell, ICellProps } from '../Cell';

interface ICellEntryProps extends Omit<ICellProps, 'image' | 'accessory'> {
  icon?: React.ComponentType<ISVGIconProps>;
}

export const CellEntry: React.FC<ICellEntryProps> = ({ icon: Icon, ...sharedProps }) => {
  return (
    <Cell
      {...sharedProps}
      image={
        Icon ? (
          <div className={styles['icon']}>
            <Icon color="icon-inverted-default" />
          </div>
        ) : undefined
      }
      accessory={<IconActionChevronRight16 color="gray40_100" />}
    />
  );
};
