import {
  OFFER_TYPES_RENT_RESIDENTIAL,
  OFFER_TYPES_RENT_COMMERCIAL,
  OFFER_TYPES_SALE_COMMERCIAL,
  OFFER_TYPES_DAILY_RESIDENTIAL,
} from './constants/offerTypes';
import { IOfferTypeSelectOptionsGroup } from './types';
import { FDealType, FOfferType } from '../../../../JsonQuery';
import { getSaleResidential } from '../../utils/getSaleResidential';

export function normalizeOfferType(offerType: FOfferType): FOfferType {
  return (offerType & FOfferType.Residential) !== 0 ? FOfferType.Residential : FOfferType.Commercial;
}

const getOfferTypes = (
  isSearchFlatShareEnabled: boolean,
): { dealType: FDealType; offerType: FOfferType; list: IOfferTypeSelectOptionsGroup[] }[] => [
  {
    dealType: FDealType.Sale,
    offerType: FOfferType.Residential,
    list: getSaleResidential(isSearchFlatShareEnabled),
  },
  {
    dealType: FDealType.Sale,
    offerType: FOfferType.Commercial,
    list: OFFER_TYPES_SALE_COMMERCIAL,
  },
  {
    dealType: FDealType.RentLongterm,
    offerType: FOfferType.Residential,
    list: OFFER_TYPES_RENT_RESIDENTIAL,
  },
  {
    dealType: FDealType.RentLongterm,
    offerType: FOfferType.Commercial,
    list: OFFER_TYPES_RENT_COMMERCIAL,
  },
  {
    dealType: FDealType.RentDaily,
    offerType: FOfferType.Residential,
    list: OFFER_TYPES_DAILY_RESIDENTIAL,
  },
];

interface IProps {
  isSearchFlatShareEnabled: boolean;
  dealType: FDealType;
  offerType: FOfferType;
}

export function getOfferTypeList({
  dealType,
  offerType,
  isSearchFlatShareEnabled,
}: IProps): IOfferTypeSelectOptionsGroup[] {
  const type = getOfferTypes(isSearchFlatShareEnabled).find(
    t => t.dealType === dealType && t.offerType === normalizeOfferType(offerType),
  );

  return type ? type.list : [];
}
