import * as React from 'react';

import { RadioButtonGroup } from '../../../../../Fields/shared/components/RadioButtonGroup';
import { FDealType } from '../../../../../JsonQuery';
import { ESpecialDealTypes } from '../../../types/specialDealTypes';
import { IModalWindowProps, ModalWindow } from '../../ModalWindow';

interface IDealType {
  label: string;
  value: FDealType | ESpecialDealTypes;
}

const DEAL_TYPES: IDealType[] = [
  { label: 'Купить', value: FDealType.Sale },
  { label: 'Снять', value: FDealType.RentLongterm },
  { label: 'Посуточно', value: FDealType.RentDaily },
];

export function getDealTypeLabel(dealType: FDealType) {
  return (DEAL_TYPES.find(({ value }) => (value as number) & dealType) as IDealType).label;
}

interface IRootDealTypeModalProps extends Omit<IModalWindowProps, 'onChange' | 'children' | 'title'> {
  value: FDealType;
  onChange(value: FDealType): void;
}

export const RootDealTypeModal: React.FC<IRootDealTypeModalProps> = ({
  value: initialValue,
  onChange,
  ...modalProps
}) => {
  const [value, setValue] = React.useState(initialValue);

  const onChangeRadio = React.useCallback((value: FDealType) => setValue(value), []);
  const handleChange = React.useCallback(() => onChange(value), [value, onChange]);

  return (
    <ModalWindow title="Зачем ищете" onChange={handleChange} {...modalProps}>
      <RadioButtonGroup spliced options={DEAL_TYPES} value={value} onChange={onChangeRadio} />
    </ModalWindow>
  );
};
