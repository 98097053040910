import { EAlertType } from '../../types/authentication';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EAuthenticationActionTypes {
  ModalOpen = 'authentication/modalOpen',
  ModalClose = 'authentication/modalClose',
}

export interface IModalOpenPayload {
  autoRefresh: boolean;
  alert: EAlertType | null;
  useDefaultView?: boolean;
  viewData?: unknown;
  source?: string | null;
}

export type TModalOpen = ITypedReduxAction<EAuthenticationActionTypes.ModalOpen, IModalOpenPayload>;
export type TModalClose = ITypedReduxAction<EAuthenticationActionTypes.ModalClose>;

export type TAuthenticationModalActions = TModalOpen | TModalClose;

export interface IAuthenticateEvent {
  autoRefresh?: boolean;
}

export enum EAuthenticationActionType {
  Succeed = 'authentication/succeed',
  Cancelled = 'authentication/cancelled',
}

export type TAuthenticationSucceed = ITypedReduxAction<EAuthenticationActionType.Succeed>;
export type TAuthenticationCancelled = ITypedReduxAction<EAuthenticationActionType.Cancelled>;

export type TAuthenticationActions = TAuthenticationSucceed | TAuthenticationCancelled;
