import { Input } from '@cian/ui-kit/input';
import IMask from 'imask';
import * as React from 'react';

import { MASK_PROPS, OWN_PROPS } from './constants';
import { TInputProps, IMaskedInputOwnProps, KeysOfUnion } from './types';

type TMaskedInputProps = IMask.AnyMaskedOptions & IMaskedInputOwnProps;

export class MaskedInput extends React.Component<TMaskedInputProps> {
  private inputEl = React.createRef<HTMLInputElement>();
  private maskRef: IMask.InputMask<IMask.AnyMaskedOptions> | undefined;

  private get maskValue() {
    if (!this.maskRef) {
      return '';
    }

    return this.maskRef.unmaskedValue;
  }

  private set maskValue(value) {
    if (!this.maskRef) {
      return;
    }

    this.maskRef.unmaskedValue = value;
  }

  public componentDidMount() {
    if (!this.props.mask) {
      return;
    }

    this.initMask();
  }

  public componentDidUpdate() {
    const { value } = this.props;
    const maskOptions = this.getMaskOptionsFromProps();

    if (maskOptions.mask) {
      if (this.maskRef) {
        this.maskRef.updateOptions(maskOptions);

        if (
          typeof value !== 'undefined' &&
          (value !== this.maskValue || (typeof value !== 'string' && this.maskRef.value === '')) &&
          !this.maskRef.el.isActive
        ) {
          this.maskValue = value;
        }
      } else {
        this.initMask(maskOptions);

        if (value !== this.maskValue) {
          this.handleAccept();
        }
      }
    } else {
      this.destroyMask();

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-explicit-any
      this.inputEl.current!.value = value as any;
    }
  }

  public componentWillUnmount() {
    this.destroyMask();
  }

  public render() {
    return (
      <Input
        data-name="MaskedInput"
        inputRef={this.inputEl}
        {...this.getNonMaskProps()}
        defaultValue={this.props.value}
      />
    );
  }

  private initMask(maskOptions = this.getMaskOptionsFromProps()) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.maskRef = IMask(this.inputEl.current!, maskOptions)
      .on('accept', this.handleAccept)
      .on('complete', this.handleComplete);

    this.maskValue = this.props.value || '';
  }

  private destroyMask() {
    if (this.maskRef) {
      this.maskRef.destroy();
      delete this.maskRef;
    }
  }

  private getMaskOptionsFromProps(): IMask.AnyMaskedOptions {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const availableOptions = Object.keys(this.props).filter(key =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      MASK_PROPS.includes(key as any),
    ) as KeysOfUnion<IMask.AnyMaskedOptions>[];

    return availableOptions.reduce((accumulator, currentValue) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (accumulator as any)[currentValue] = (this.props as any)[currentValue];

      return accumulator;
    }, {} as IMask.AnyMaskedOptions);
  }

  private getNonMaskProps(): TInputProps {
    const availableOptions = Object.keys(this.props).filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      key => ![...MASK_PROPS, ...OWN_PROPS].includes(key as any),
    ) as (keyof TInputProps)[];

    return availableOptions.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = this.props[currentValue];

      return accumulator;
    }, {} as TInputProps);
  }

  private handleAccept = () => {
    if (this.props.onAccept) {
      this.props.onAccept(this.maskValue);
    }
  };

  private handleComplete = () => {
    if (this.props.onComplete) {
      this.props.onComplete(this.maskValue);
    }
  };
}
