import { term, TJsonQuery } from '../../../packages/JsonQuery';
import { EFiltersActionType } from '../../actions/filters';
import { TReduxActions } from '../../types/redux';

const defaultState: TJsonQuery = {
  _type: 'flatrent',
  engine_version: term(2),
};

export function jsonQueryReducer(state: TJsonQuery = defaultState, action: TReduxActions): TJsonQuery {
  switch (action.type) {
    case EFiltersActionType.JsonQueryChanged:
      return action.payload.nextJsonQuery;
  }

  return state;
}
