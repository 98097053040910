/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetRegionMetaModel,
  IGetRegionMetaRequest,
  IMappers,
  IGetRegionMetaResponse,
  IGetRegionMetaResponseError,
  TGetRegionMetaResponse,
} from './types';

const defaultConfig: TGetRegionMetaModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'geo-temp-layer',
  pathApi: '/v1/get-region-meta/',
  hostType: 'api',
} as TGetRegionMetaModel;

function createGetRegionMetaModel(parameters: IGetRegionMetaRequest): TGetRegionMetaModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetRegionMetaOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetRegionMetaRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetRegionMeta<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetRegionMetaOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetRegionMetaResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetRegionMetaModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetRegionMetaResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetRegionMetaResponseError);
    }
  }

  return { response, statusCode } as TGetRegionMetaResponse;
}

export { defaultConfig, createGetRegionMetaModel, fetchGetRegionMeta };
