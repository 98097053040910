import { ERegionMetaActionType } from './types';
import { getTermsValue } from '../../../packages/JsonQuery';
import { EXPANSION_REGION_DISABLE_COOKIE } from '../../constants/region';
import { fetchRegionMetaWithExpansion } from '../../services/fetchRegionMetaWithExpansion';
import { TThunkAction } from '../../types/redux';
import { IRegionMetaState } from '../../types/regionMeta';
import { getCookie } from '../../utils/cookies';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { getReducedRegion } from '../../utils/region';

const updateRegionMetaRaw = actionGenerator<ERegionMetaActionType.Changed, IRegionMetaState>(
  ERegionMetaActionType.Changed,
);

export function updateRegionMeta(): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const {
      filters: { jsonQuery },
    } = getState();
    const regionId = getTermsValue('region')(jsonQuery);

    if (!regionId) {
      return;
    }

    const regionMeta = await fetchRegionMetaWithExpansion(context, {
      locationId: getReducedRegion(regionId),
      jsonQuery,
      expansionRegionCookie: getCookie(EXPANSION_REGION_DISABLE_COOKIE),
    });

    if (!regionMeta) {
      return;
    }

    dispatch(updateRegionMetaRaw(regionMeta));
  };
}
